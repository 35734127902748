import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/navbar/Navbar';

interface PageWrapperProps {}

const PageWrapper: React.FC<PageWrapperProps> = ({}) => {
  return (
    <>
      <Header bg='rgba(250, 250, 250, 1)' color='#000' />
      <Navbar bg='blue.main' color='white' />
      <Outlet />
      <Footer />
    </>
  );
};

export default PageWrapper;
