import {
  Box,
  Image,
  Stack,
  Text,
  chakra,
  Flex,
  Button,
  Alert,
  AlertIcon,
  SimpleGrid,
} from '@chakra-ui/react';
import { useAppSelector } from '../../../app/hooks';
import Header from '../../../components/Header';
import Loader from '../../../components/Loader';
import Navbar from '../../../components/navbar/Navbar';

interface PartWraperProps {
  title: string;
  text?: [string, string];
  header?: JSX.Element;
  part: string;
  cards: { number: number; subText?: string; text: string }[];
  button: {
    text: string;
    onClick: () => void;
  };
  children: React.ReactNode;
}

const PartWraper: React.FC<PartWraperProps> = ({
  title,
  part,
  cards,
  button,
  text,
  children: content,
  header,
}) => {
  const { loading, error } = useAppSelector((state) => {
    return {
      loading: state.exam.loading,
      error: state.exam.error,
    };
  });
  return (
    <>
      <Header bg='blue.main' color='white' />
      <Navbar bg='rgba(250, 250, 250, 1)' color='#000' />
      <Stack direction={['column', null, 'row']}>
        <Box
          order={[1, 1, 0]}
          mx='auto'
          w={['100%', null, '60%']}
          ps={['20px', null, '100px']}
          pe='20px'
          pt='50px'
        >
          {loading ? (
            <Loader size='xxl' />
          ) : error ? (
            <Alert status='error'>
              <AlertIcon />
              {error}
            </Alert>
          ) : (
            <>
              <Text
                color='#407BFF'
                fontFamily='Gilroy-Bold'
                fontSize={['24px', null, '30px', null, '35px', null, '50px']}
                mb='20px'
              >
                {part === 'Part One' && (
                  <span>
                    1<sup>st</sup>
                  </span>
                )}{' '}
                {title}
              </Text>
              {text && (
                <Text
                  fontSize={['14px', null, '16px', null, '20px', '20px']}
                  fontWeight='600'
                  mb='20px'
                >
                  {text[0]}
                  <chakra.span color='#F8AA05'>{text[1]}</chakra.span>
                </Text>
              )}
              {header}
              <Box mt='40px'>
                <Text
                  fontFamily='Gilroy-Bold'
                  fontSize={['20px', null, '22px', null, '26px', '30px']}
                >
                  {part}
                </Text>
                <SimpleGrid
                  columns={[2, 3]}
                  fontFamily='Gilroy-Bold'
                  mt={{ base: '16px', xxl: '20px' }}
                  gap='25px'
                  flexWrap='wrap'
                >
                  {cards.map((card, index) => (
                    <Box
                      bg='rgba(255, 255, 255, 0.4)'
                      borderRadius='10px'
                      boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)'
                      p='10px'
                      key={index}
                    >
                      <Text
                        color='#F8AA05'
                        fontSize={['30px', null, null, '35px', null, '40px']}
                      >
                        {card.number}{' '}
                        {card.subText && (
                          <chakra.sub fontSize={['20px']}>
                            {card.subText}
                          </chakra.sub>
                        )}
                      </Text>
                      <Text
                        color='#09003F'
                        fontSize={['22px', null, '26px', null, '28px']}
                      >
                        {card.text}
                      </Text>
                    </Box>
                  ))}
                </SimpleGrid>
                <Button
                  colorScheme='blue'
                  bg='#407BFF'
                  px='50px'
                  mt='40px'
                  onClick={button.onClick}
                >
                  {button.text}
                </Button>
              </Box>
            </>
          )}
        </Box>
        {content}
      </Stack>
    </>
  );
};

export default PartWraper;
