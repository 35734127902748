import { Box, Image, SimpleGrid, Text, Button } from '@chakra-ui/react';
import image from '../../../../assests/images/quesTest.png';
import styled from 'styled-components';
import QuestionControls from './QuestionControls';
import pauseIcon from '../../../../assests/icons/test.svg';
import flagIcon from '../../../../assests/icons/flag.svg';
import markedIcon from '../../../../assests/icons/marked.svg';
import { useContext } from 'react';
import { questionContext } from '../../../../contexts/QuestionContextProvider';
import { useAppDispatch } from '../../../../app/hooks';
import { toggleQuestionMark } from '../../../../app/features/exam/slice';
import { useTranslate } from '../../../../languages';
import { getQuestionCorrectAnswersCount } from '../../../../util/exam-utils';
import useNotification from '../../../../hooks/notification';

interface MultiChoiceQuestionProps {}

const MultiChoiceQuestion: React.FC<MultiChoiceQuestionProps> = ({}) => {
  const {
    question,
    part: deptId,
    examId,
    userId,
    getUserQuestionAnswer,
    addAnswer,
    editAnswer,
    nextQuestion,
  } = useContext(questionContext);
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const notify = useNotification();
  const userAnswer = getUserQuestionAnswer(question.questionId, deptId);

  const { Answer, questionId, questiontext } = question;

  const correctAnsersCount = getQuestionCorrectAnswersCount(question);

  return (
    <Box pt='20px' pos='relative' w={['100%', null, '70%']}>
      {deptId > 1 && (
        <Button
          bg='#fff'
          p='10px'
          borderRadius={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08)'
          cursor='pointer'
          isActive={question.isMarked}
          _active={{
            color: '#407BFF',
          }}
          pos='absolute'
          top='25px'
          right='10px'
          onClick={() => {
            dispatch(toggleQuestionMark(question.questionId));
          }}
          gap={2}
        >
          {t('mark-q')}
          {question.isMarked ? (
            <Image src={markedIcon} />
          ) : (
            <Image src={flagIcon} />
          )}
        </Button>
      )}
      <Image
        key={Math.random()}
        src={question.questionImage}
        w='100%'
        minH='11rem'
        maxH={['190px', null, null, '320px', '350px', '400px']}
      />
      <Text
        fontFamily='Gilroy-Bold'
        color='#09003F'
        fontSize={['24px', null, null, null, '18px', '30px']}
        my='20px'
      >
        {questiontext}
      </Text>
      <Text fontSize={'14px'} mb={3} color='#09003F'>{`${t(
        'choose'
      )} ${correctAnsersCount} ${t('answers')}`}</Text>
      <SimpleGrid rowGap='20px' columnGap='50px' columns={[1, 1, 2]} mb='50px'>
        {Answer.map((answer, index) => (
          <AnswerCard
            key={answer.answerId}
            className={
              userAnswer?.userAnswers.includes(answer.answerId.toString())
                ? 'selected'
                : ''
            }
            onClick={() => {
              const answerId = answer.answerId.toString();

              let newUserAnswer = { ...userAnswer };

              // if question has single correct answer
              if (correctAnsersCount <= 1) {
                newUserAnswer.userAnswers = [answerId];
              } else if (userAnswer.userAnswers.includes(answerId)) {
                newUserAnswer.userAnswers = newUserAnswer.userAnswers.filter(
                  (a) => a !== answerId
                );
              } else {
                if (newUserAnswer.userAnswers.length >= correctAnsersCount) {
                  return notify.info(t('user-answer-max'));
                }
                newUserAnswer.userAnswers.push(answerId);
              }
              editAnswer(newUserAnswer);
            }}
          >
            {answer.answerText}
          </AnswerCard>
        ))}
      </SimpleGrid>
      <QuestionControls next={nextQuestion} />
    </Box>
  );
};

export default MultiChoiceQuestion;

export const AnswerCard = styled.div`
  background-color: #fff;
  padding: 12px 20px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600px;
  border: 2px solid transparent;
  user-select: none;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 12px;
  }

  &:hover {
    border-color: #407bff;
  }
  &.selected {
    background-color: #407bff;
    color: #fff;
  }
  &.wrong {
    background-color: #c01616;
    color: #fff;
  }
  &.correct {
    background-color: #438624;
    color: #fff;
  }
`;
