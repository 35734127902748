import { configureStore, AnyAction } from '@reduxjs/toolkit';

// reducers
import userSlice from './features/user/slice';
import packagesSlice from './features/packages/slice';
import examSlice from './features/exam/slice';
import settingsSlice from './features/settings/slice';

const store = configureStore({
  reducer: {
    user: userSlice,
    packages: packagesSlice,
    exam: examSlice,
    settings: settingsSlice,
    lastAction: (state: AnyAction | undefined, action) => {
      const arg = {...action.meta?.arg};
      delete arg?.data;
      return {
        type: action.type,
        arg
      };
    },
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
