import { Text, Divider, SimpleGrid, Skeleton, Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPackageExams } from '../../app/features/user/actions';
import ExamCard from './ExamCard';
import Loader from '../../components/Loader';
import { useTranslate } from '../../languages';
import ProfileSection from './ProfileSection';
import { ExamDetails } from '../../types';

interface ExamsListProps {}

const ExamsList: React.FC<ExamsListProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const { packageId } = useParams();
  const { loading, error, userId, exams } = useAppSelector((state) => {
    return {
      loading: state.user.loading,
      error: state.user.error,
      userId: state.user.data?.userId,
      exams: state.user.currentPackageExams,
    };
  });

  useEffect(() => {
    if (userId && packageId) {
      dispatch(getPackageExams({ userId: +userId, packageId: +packageId }));
    }
  }, [userId, packageId]);

  const examsByLanguage: {
    title: string;
    langId: number;
    exams: ExamDetails[];
  }[] = [
    {
      title: t('ar-exams'),
      langId: 1,
      exams: [],
    },
    {
      title: t('en-exams'),
      langId: 2,
      exams: [],
    },
    {
      title: t('du-exams'),
      langId: 3,
      exams: [],
    },
  ];

  for (const exam of exams) {
    examsByLanguage[exam.examLanguageId - 1].exams.push(exam);
  }

  return (
    <ProfileSection title={t('exams-list')}>
      {loading ? (
        <SimpleGrid columns={[1, 1, 1, 2, 2, 3]} gap='40px'>
          {[1, 2, 4].map((item) => (
            <Skeleton key={item} isLoaded={!loading} h='140px'></Skeleton>
          ))}
        </SimpleGrid>
      ) : (
        <>
          {examsByLanguage.map((lang) => {
            if (lang.exams.length === 0) return null;
            return (
              <Box key={lang.langId}>
                <Text
                  fontFamily='Inter'
                  fontWeight={600}
                  fontSize='1.6rem'
                  mb={3}
                >
                  {lang.title}
                </Text>
                <SimpleGrid columns={[1, 1, 1, 2, 2, 3]} gap='40px'>
                  {lang.exams.map((exam) => (
                    <ExamCard
                      key={exam.examId}
                      packageId={packageId!}
                      {...exam}
                    />
                  ))}
                </SimpleGrid>
                <Divider borderBottomWidth='3px' my={9} />
              </Box>
            );
          })}
        </>
      )}
    </ProfileSection>
  );
};

export default ExamsList;
