import {
  Container,
  Flex,
  Box,
  Text,
  Image,
  HStack,
  Button,
} from '@chakra-ui/react';
import imageOne from '../../assests/images/landing/image-1.svg';
import teacherIcon from '../../assests/icons/landing/teachertest.svg';
import questionIcon from '../../assests/icons/landing/question-mark.svg';
import noteIcon from '../../assests/icons/landing/note-2.svg';
import { useTranslate } from '../../languages';
import { Link } from 'react-router-dom';

interface LandingHeaderProps {}

const LandingHeader: React.FC<LandingHeaderProps> = ({}) => {
  const t = useTranslate();
  return (
    <Container maxW='7xl' pt='32px' mb='100px'>
      <Flex wrap='wrap'>
        <Box order={{ base: 1, lg: 0 }} w={{ base: '100%', lg: '50%' }} pt='5'>
          <Text
            fontSize={['35px', '40px', '42px', '46px', null, '67px']}
            bgGradient='linear-gradient(80.65deg, #1885F0 4.36%, #27209B 98%)'
            bgClip='text'
            fontWeight='bold'
            mb={['10px', null, '20px', '35px', null, '48px']}
            fontFamily='Gilroy-Bold'
          >
            {t('landing.header.title')}
          </Text>
          <Text
            fontWeight='600'
            fontSize={['15px', '20px']}
            fontFamily='Inter'
            color='#505050'
          >
            {t('landing.header.subtitle')}
          </Text>
          <HStack
            spacing={['10px', '12px', '16px']}
            fontSize={['14px', '16px', '20px', null, '22px']}
            mt='4rem'
            fontFamily='Inter'
          >
            <Button
              as={Link}
              to='/signin'
              colorScheme='blue'
              color='rgba(255, 255, 255, 1)'
              borderRadius='none'
              px='10'
              fontSize='md'
            >
              {t('try-trial-exam')}
            </Button>
            {/* <Text color='blue.main' fontWeight='semibold' cursor='pointer'>
              {t('start-journey')}
            </Text> */}
          </HStack>
          <div className='boxes' style={{ fontFamily: 'Inter' }}>
            <div className='notch'>
              <div className='content'>
                <Image
                  boxSize={['19px', '20px', '22px', '30px']}
                  src={teacherIcon}
                  m='auto'
                />
                <Text
                  fontSize={['12px', '15px', '18px', '22px', '24px', '32px']}
                  color='yellow.main'
                  fontWeight='bold'
                >
                  +500
                </Text>
                <Text
                  fontSize={['8px', '10px', '12px', '16px', '18px', '22px']}
                >
                  {t('successsful-student')}
                </Text>
              </div>
            </div>
            <div className='notch'>
              <div className='content'>
                <Image
                  boxSize={['19px', '20px', '22px', '30px']}
                  src={questionIcon}
                  m='auto'
                />
                <Text
                  fontSize={['12px', '15px', '18px', '22px', '24px', '32px']}
                  color='yellow.main'
                  fontWeight='bold'
                >
                  +4000
                </Text>
                <Text
                  fontSize={['8px', '10px', '12px', '16px', '18px', '22px']}
                >
                  {t('questions')}
                </Text>
              </div>
            </div>
            <div className='notch'>
              <div className='content'>
                <Image
                  boxSize={['19px', '20px', '22px', '30px']}
                  src={noteIcon}
                  m='auto'
                />
                <Text
                  fontSize={['12px', '15px', '18px', '22px', '24px', '32px']}
                  color='yellow.main'
                  fontWeight='bold'
                >
                  +20
                </Text>
                <Text
                  fontSize={['8px', '10px', '12px', '16px', '18px', '22px']}
                >
                  {t('exams')}
                </Text>
              </div>
            </div>
          </div>
        </Box>
        <Box order={0} w={{ base: '100%', lg: '50%' }}>
          <Image
            src={imageOne}
            w='100%'
            maxH={[null, null, null, null, '530px', '100%']}
          />
        </Box>
      </Flex>
    </Container>
  );
};

export default LandingHeader;
