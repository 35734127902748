import {
  Container,
  Box,
  Flex,
  List,
  ListItem,
  Spacer,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Image,
} from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../Logo';
import { useRef } from 'react';
import burgerIcon from '../../assests/icons/burger.svg';
import { LoggedInUserMenu, UnLoggedInUserMenu } from './UserMenu';
import LanguagesList from '../LanguagesList';

interface NavbarMobilProps {
  backgroundColor: string;
  isLoggedIn: boolean;
  items: {
    text: string;
    to: string;
  }[];
}

const NavbarMobil: React.FC<NavbarMobilProps> = ({
  backgroundColor,
  isLoggedIn,
  items,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<any>();

  return (
    <Box
      bg={backgroundColor}
      display={{ base: 'block', lg: 'none' }}
      py='15px'
      boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)'
    >
      <Container maxW='7xl'>
        <Flex alignItems='center'>
          <Logo />
          <Spacer />
          <Button ref={btnRef} colorScheme='white' onClick={onOpen}>
            <Image src={burgerIcon} />
          </Button>
        </Flex>
        <Drawer
          isOpen={isOpen}
          placement={document.dir === 'rtl' ? 'right' : 'left'}
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Box py='10' ps='3'>
              {isLoggedIn ? <Logo /> : <UnLoggedInUserMenu />}
            </Box>
            <DrawerCloseButton />
            <Divider />
            <Divider />
            <DrawerBody>
              <List>
                {items.map((item) => (
                  <NavLink to={item.to} key={item.text}>
                    <ListItem
                      fontSize='16px'
                      fontWeight='600'
                      mb='20px'
                      fontFamily='Inter'
                      onClick={onClose}
                    >
                      {item.text}
                    </ListItem>
                  </NavLink>
                ))}
              </List>
              <Divider />
              <LanguagesList color='#000' />
              {isLoggedIn && <LoggedInUserMenu />}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Container>
    </Box>
  );
};

export default NavbarMobil;
