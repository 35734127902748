import {
  Box,
  Container,
  HStack,
  Stack,
  Text,
  Image,
  Button,
  SimpleGrid,
  chakra,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from 'react';

import imageForNewUser from '../../assests/images/home-car-new.png';
import imageForOldUser from '../../assests/images/home-car-old.png';
import trueIcon from '../../assests/icons/true.svg';
import RepetitionChart from './RepetitionChart';
import ExamsQuestionsRevison from './ExamsQuestionsRevison';
import { Link } from 'react-router-dom';
import { getUserRecapData } from '../../app/features/user/actions';
import Loader from '../../components/Loader';
import ExamsPartsRevision from './ExamsPartsRevision';
import { useTranslate } from '../../languages';

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const { name, userId, error, loading, userInfo } = useAppSelector((state) => {
    return {
      loading: state.user.loading,
      error: state.user.error,
      name: state.user.data?.userName,
      userId: state.user.data?.userId,
      userInfo: state.user.recapData,
    };
  });

  useEffect(() => {
    if (userId) {
      dispatch(getUserRecapData({ userId: +userId }));
    }
  }, []);

  if (loading) {
    return <Loader size='xxl' />;
  }

  return (
    <Box bg='#E5E5E5' minH='100vh' py='50px'>
      <Container maxW='7xl'>
        <Box
          color='#407BFF'
          fontFamily='Gilroy-Bold'
          fontSize={['20px', '25px', null, '30px', null, '35px', '40px']}
        >
          <Text>{t('welcome-back')}</Text>
          <Text>{name}...</Text>
        </Box>
        <Box
          bg='linear-gradient(357.97deg, #407BFF -1.63%, #72C2FC 101.23%)'
          display='flex'
          flexWrap='wrap'
          p={['20px', null, null, '25px', '30px']}
          mb='20px'
        >
          <Box w={['100%', null, '70%']} order={[1, 1, 0]}>
            <Text
              color='darkBlue'
              fontFamily='Gilroy-Bold'
              fontSize={['24px', null, null, '28px', null, '32px']}
              mb='22px'
            >
              {userInfo ? t('home.title.old-user') : t('home.title.new-user')}
            </Text>
            {userInfo ? (
              <>
                <Box
                  color='#fff'
                  fontFamily='Inter'
                  fontWeight={600}
                  fontSize='20px'
                  mb='20px'
                >
                  <Text mb='10px'>{t('home-msg')}</Text>
                  <Text>
                    <chakra.span color='#F2DA00'>91% </chakra.span>
                    {t('students-passed-on-frist-try')}
                  </Text>
                </Box>
                <Link to='/profile'>
                  <Button colorScheme='darkBlue' borderRadius={0}>
                    {t('start-training')}
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <HStack mb='15px'>
                  <Image src={trueIcon} />
                  <Text
                    fontWeight={600}
                    fontFamily='Inter'
                    fontSize={['16px', null, null, null, '20px']}
                    color='#fff'
                  >
                    {t('select-suitable-pack')}
                  </Text>
                </HStack>
                <HStack mb='25px'>
                  <Image src={trueIcon} />
                  <Text
                    fontWeight={600}
                    fontFamily='Inter'
                    fontSize={['16px', null, null, null, '20px']}
                    color='#fff'
                  >
                    {t('select-suitable-pack')}
                  </Text>
                </HStack>
                <HStack>
                  <Link to='/packges'>
                    <Button colorScheme='darkBlue' borderRadius={0}>
                      {t('select-your-pack')}
                    </Button>
                  </Link>
                  <Text
                    fontFamily='Inter'
                    fontWeight={600}
                    color='#fff'
                    fontSize='13px'
                    cursor='pointer'
                  >
                    <Link to='/free-exam'>{t('try-trial-exam')}</Link>
                  </Text>
                </HStack>
              </>
            )}
          </Box>
          <Image
            src={userInfo ? imageForOldUser : imageForNewUser}
            w={['100%', null, '30%']}
            order={[0, 0, 1]}
          />
        </Box>
        {userInfo && (
          <SimpleGrid columns={[1, 1, 2, 3]} spacing='12px'>
            <ExamsPartsRevision
              partOneCount={userInfo.partOneCount}
              partTwoCount={userInfo.partTwoCount}
              partThreeCount={userInfo.partThreeCount}
              userPartOneCount={userInfo.userPartOneCount}
              userPartTwoCount={userInfo.userPartTwoCount}
              userPartThreeCount={userInfo.userPartThreeCount}
            />
            <RepetitionChart
              title={t('exams-repetitions')}
              text={t('repetitions-y-spent')}
              totalNumber={userInfo.packageReapetCount}
              subNumber={userInfo.userReapetCount}
            />
            <ExamsQuestionsRevison
              numberOfQuestions={userInfo.examQuestionCount}
              numberOfCorrectAnswers={userInfo.correctAnswerCount}
              numberOfWrongAnswers={userInfo.incorrectAnswerCount}
              numberOfUnAnsweredQuestions={userInfo.emptyAnswerCount}
            />
          </SimpleGrid>
        )}
      </Container>
    </Box>
  );
};

export default Home;
