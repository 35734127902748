import { Box, Image, chakra, Text, HStack } from '@chakra-ui/react';
import ContactForm from '../components/ContactForm';
import Header from '../components/Header';
import Navbar from '../components/navbar/Navbar';
import homeImage from '../assests/images/home.png';
import carImage from '../assests/images/car.png';
import Logo from '../components/Logo';
import SocialMedialIcons from '../components/SocialMediaIcons';
import Footer from '../components/Footer';
import { useTranslate } from '../languages';
import { useAppSelector } from '../app/hooks';

interface AboutUsProps {}

const AboutUs: React.FC<AboutUsProps> = ({}) => {
  const t = useTranslate();
  const about = useAppSelector((state) => state.settings.websiteContent?.about)

  return (
    <Box bg='#E5E5E5' pb='170px'>
      <chakra.picture overflow='hidden' h='200px' bg='red'>
        <source media='(min-width: 768px)' srcSet={homeImage} />
        <source media='(max-width: 768px)' srcSet={carImage} />
        <Image src={homeImage} w='100%' />
      </chakra.picture>
      <Box
        maxW='6xl'
        mx={['20px', null, 'auto']}
        bg='#fff'
        p='30px'
        mb='100px'
        transform='translateY(-40px)'
      >
        <Text
          color='#000000'
          fontFamily='Gilroy-Bold'
          fontSize={['32px', null, '34px', null, '36px', '40px']}
          mb={['36px', null, '38px']}
        >
          {t('about-us')}
        </Text>
        <HStack>
          <Box display={['none', null, 'block']} w='20%'>
            <Logo />
            <SocialMedialIcons
              filter='brightness(0) saturate(100%) invert(6%) sepia(43%) saturate(5451%) hue-rotate(247deg) brightness(103%) contrast(119%)'
              mt='30px'
            />
          </Box>
          <Text flex={1}>
            {about}
          </Text>
        </HStack>
      </Box>
      <ContactForm />
    </Box>
  );
};

export default AboutUs;
