import { Button, HStack } from '@chakra-ui/react';
import { langs, useTranslate } from '../languages';

interface PackageTypesButtonsProps {
  displayOnEnd?: boolean;
  changeLanguage: (id: number) => void;
  currentLanguageId: number;
}

const PackageTypesButtons: React.FC<PackageTypesButtonsProps> = ({
  displayOnEnd,
  changeLanguage,
  currentLanguageId
}) => {
  const t = useTranslate();
  return (
    <HStack
      spacing='5'
      mt={displayOnEnd ? '20px' : undefined}
      mb={['30px', null, null, '40px', null, '60px']}
      justifyContent={displayOnEnd ? ['center', null, 'flex-end'] : undefined}
      fontFamily='Inter'
    >
      {langs.map((lang, index) => (
        <Button
          key={lang.id}
          borderRadius='0'
          border='1px solid rgba(80, 80, 80, 0.88)'
          color='rgba(80, 80, 80, 0.88)'
          bg='transparent'
          _active={{ bg: 'blue.main', color: '#fff' }}
          isActive={currentLanguageId === lang.id}
          fontSize={['10px', '12px', '16px', null, null, '20px']}
          onClick={() => changeLanguage(lang.id)}
        >
          {t('packages')[index]}
        </Button>
      ))}
    </HStack>
  );
};

export default PackageTypesButtons;
