import {
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Button,
  Box,
  HStack,
  Avatar,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logoutIcon from '../../assests/icons/logout.svg';
import profileIcon from '../../assests/icons/profile.svg';
import settingsIcon from '../../assests/icons/settings.svg';
import noteIcon from '../../assests/icons/landing/note-2.svg';
import useLogout from '../../hooks/logout';
import { useTranslate } from '../../languages';
import { useAppSelector } from '../../app/hooks';
import { BASE_URL } from '../../util/constants';

export const UnLoggedInUserMenu: React.FC = () => {
  const t = useTranslate();
  return (
    <HStack spacing='20px'>
      <Link to='/signin'>
        <Text fontWeight='semibold' cursor='pointer'>
          {t('signin')}
        </Text>
      </Link>
      <Link to='/signup'>
        <Button colorScheme='blue' borderRadius='none' px='10' fontSize='md'>
          {t('create-account')}
        </Button>
      </Link>
    </HStack>
  );
};

export const LoggedInUserMenu: React.FC = () => {
  const logout = useLogout();
  const t = useTranslate();
  const { username, userImage } = useAppSelector((state) => {
    return {
      username: state.user.data?.userName,
      userImage: state.user.data?.userImage,
    };
  });

  const imageUrl = BASE_URL + userImage;

  return (
    <Menu placement='bottom'>
      <MenuButton>
        <Box display='flex' alignItems='center' columnGap='10px'>
          <Avatar boxSize='2.5rem' src={imageUrl} display='inline' />
          <Text display={{ base: 'inline', lg: 'none' }}>{username}</Text>
        </Box>
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Link}
          to='/profile'
          mb='10px'
          icon={<Image src={profileIcon} boxSize='16px' />}
        >
          {t('profile')}
        </MenuItem>
        <MenuItem
          as={Link}
          to='/profile/suscribed-packs'
          mb='10px'
          icon={<Image src={noteIcon} boxSize='16px' />}
        >
          {t('packs')}
        </MenuItem>
        <MenuItem
          as={Link}
          to='/profile/edit'
          mb='10px'
          icon={<Image src={settingsIcon} boxSize='16px' />}
        >
          {t('edit-profile')}
        </MenuItem>
        <MenuItem
          icon={<Image src={logoutIcon} boxSize='16px' />}
          onClick={logout}
        >
          {t('logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
