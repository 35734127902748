import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
  chakra,
  Box,
  Grid,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useAppSelector } from '../../../../app/hooks';
import { questionContext } from '../../../../contexts/QuestionContextProvider';
import { useContext } from 'react';
import { useTranslate } from '../../../../languages';

interface QuestionIndexProps {
  closeModal: () => void;
  isOpen: boolean;
}

const QuestionIndex: React.FC<QuestionIndexProps> = ({
  closeModal,
  isOpen,
}) => {
  const t = useTranslate();
  const { questions } = useAppSelector((state) => {
    return {
      questions: state.exam.data?.Question,
    };
  });

  const { goToQuestion } = useContext(questionContext);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent maxW='2xl'>
        <ModalCloseButton
          fontSize={18}
          {...(document.dir === 'rtl' ? { left: 2, right: 'none' } : {})}
        />
        <ModalHeader>
          <Text
            color='#09003F'
            fontFamily='Gilroy-Bold'
            fontSize={['24px', null, '26px', null, '30px', '32px']}
          >
            {t('q-index')}
          </Text>
          <Text
            color='#505050'
            fontFamily='Inter'
            fontWeight='500'
            fontSize={['16px', null, '19px', null, null, '20px']}
          >
            {t('q-index-title')[0]}
            <chakra.span color='#407BFF'>{t('q-index-title')[1]}</chakra.span>
          </Text>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridTemplateColumns='repeat(auto-fit, minmax(50px, 55px))'
            gap='15px'
            justifyContent='center'
          >
            {questions &&
              questions.map((q, index) => (
                <Box
                  key={q.questionId}
                  bg={q.isMarked ? 'crimson' : '#09003F'}
                  fontFamily='Gilroy-Bold'
                  fontSize='28px'
                  color='#fff'
                  textAlign='center'
                  borderRadius='2px'
                  cursor='pointer'
                  onClick={() => {
                    goToQuestion(index);
                    closeModal();
                  }}
                >
                  {index + 1}
                </Box>
              ))}
          </Grid>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default QuestionIndex;
