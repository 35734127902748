import { Box, HStack, Progress, VStack, Text, Flex } from '@chakra-ui/react';
import CardWrapper from './CardWrapper';
import noteIcon from '../../assests/icons/landing/note-2.svg';
import { useTranslate } from '../../languages';

interface ExamsPartsRevisionProps {
  partOneCount: number;
  partTwoCount: number;
  partThreeCount: number;
  userPartOneCount: number;
  userPartTwoCount: number;
  userPartThreeCount: number;
}

const ExamsPartsRevision: React.FC<ExamsPartsRevisionProps> = ({
  partOneCount,
  partTwoCount,
  partThreeCount,
  userPartOneCount,
  userPartTwoCount,
  userPartThreeCount,
}) => {
  const t = useTranslate();
  const partOneValue = Math.round((userPartOneCount / partOneCount) * 100),
    partTwoValue = Math.round((userPartTwoCount / partTwoCount) * 100),
    partThreeValue = Math.round((userPartThreeCount / partThreeCount) * 100);

  // console.log({ partOneValue, userPartOneCount, partOneCount });

  return (
    <CardWrapper title={t('revision-y-exams')} icon={noteIcon}>
      <VStack>
        <Box w='100%'>
          <Flex
            justifyContent='space-between'
            color='#09003F'
            fontFamily='Inter'
            fontWeight={500}
            fontSize='12px'
          >
            <Text>{t('part-1')}</Text>
            <Text>
              {userPartOneCount} / {partOneCount} Q
            </Text>
          </Flex>
          <Progress
            value={partOneValue}
            w='100%'
            h='8px'
            borderRadius='5px'
            colorScheme='green'
          />
        </Box>
        <Box w='100%'>
          <Flex
            justifyContent='space-between'
            color='#09003F'
            fontFamily='Inter'
            fontWeight={500}
            fontSize='12px'
          >
            <Text>{t('part-2')}</Text>
            <Text>
              {userPartTwoCount} / {partTwoCount} Q
            </Text>
          </Flex>
          <Progress
            value={partTwoValue}
            w='100%'
            h='8px'
            borderRadius='5px'
            colorScheme='green'
          />
        </Box>
        <Box w='100%'>
          <Flex
            justifyContent='space-between'
            color='#09003F'
            fontFamily='Inter'
            fontWeight={500}
            fontSize='12px'
          >
            <Text>{t('part-3')}</Text>
            <Text>
              {userPartThreeCount} / {partThreeCount} Q
            </Text>
          </Flex>
          <Progress
            value={partThreeValue}
            w='100%'
            h='8px'
            borderRadius='5px'
            colorScheme='green'
          />
        </Box>
      </VStack>
    </CardWrapper>
  );
};

export default ExamsPartsRevision;
