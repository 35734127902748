import { Box } from '@chakra-ui/react';
import ExamPartsCharts from '../exam/result/ExamPartsCharts';
import CardWrapper from './CardWrapper';
import questionMarkIcon from '../../assests/icons/landing/question-mark.svg';
import { useTranslate } from '../../languages';

interface ExamsQuestionsRevisonProps {
  numberOfQuestions: number;
  numberOfCorrectAnswers: number;
  numberOfUnAnsweredQuestions: number;
  numberOfWrongAnswers: number;
}

const ExamsQuestionsRevison: React.FC<ExamsQuestionsRevisonProps> = ({
  numberOfCorrectAnswers,
  numberOfQuestions,
  numberOfUnAnsweredQuestions,
  numberOfWrongAnswers
}) => {
  const t = useTranslate();
  return (
    <CardWrapper title={t('answered-questions')} icon={questionMarkIcon}>
      <ExamPartsCharts
        numberOfQuestions={numberOfQuestions}
        numberOfCorrectAnswers={numberOfCorrectAnswers}
        numberOfUnAnsweredQuestions={numberOfUnAnsweredQuestions}
        numberOfWrongAnswers={numberOfWrongAnswers}
      />
    </CardWrapper>
  );
};

export default ExamsQuestionsRevison;
