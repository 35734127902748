import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Container,
} from '@chakra-ui/react';

interface ErrorFallbackProps {}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({}) => {
  return (
    <Container mt={10}>
      <Alert
        status='error'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
      >
        <AlertIcon />
        <AlertTitle>App crached</AlertTitle>
        <AlertDescription>
          Error on the app please reload the page and try again
        </AlertDescription>
      </Alert>
    </Container>
  );
};

export default ErrorFallback;
