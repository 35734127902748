import { Box, Divider, SimpleGrid, Text, Image } from '@chakra-ui/react';
import plusIcon from '../../assests/icons/plus.svg';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from 'react';
import { getUserPackages } from '../../app/features/user/actions';
import SubscriedPackageCard from './SubscribiedPackageCard';
import Loader from '../../components/Loader';
import { useTranslate } from '../../languages';
import ProfileSection from './ProfileSection';
import { Link } from 'react-router-dom';

interface SubscribedPackgesProps {}

const SubscribedPackges: React.FC<SubscribedPackgesProps> = ({}) => {
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const { loading, error, packages, langId, userId } = useAppSelector(
    (state) => {
      return {
        loading: state.user.loading,
        error: state.user.error,
        packages: state.user.packages,
        langId: state.settings.lang.id,
        userId: state.user.data?.userId,
      };
    }
  );

  useEffect(() => {
    if (userId) {
      dispatch(getUserPackages({ userId: +userId, langId }));
    }
  }, [userId, langId]);

  return (
    <ProfileSection title={t('subscribed-packs')}>
      {loading ? (
        <Loader size='xl' />
      ) : (
        <SimpleGrid columns={[1, 1, 2]} gap='24px'>
          {packages.map((p) => (
            <SubscriedPackageCard key={p.packageId} {...p} />
          ))}
          <Box
            as={Link}
            to='/packges'
            bg='#F5F4F4'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            boxShadow='0px 0px 2.528px rgba(0, 0, 0, 0.04), 0px 2.528px 5.056px rgba(0, 0, 0, 0.06)'
            fontFamily='Inter'
            fontWeight={500}
            fontSize={['15px', null, '18px', null, '20px', '24px']}
            cursor='pointer'
            minH='172px'
          >
            <Image src={plusIcon} />
            <Text>{t('add-new-pack')}</Text>
          </Box>
        </SimpleGrid>
      )}
    </ProfileSection>
  );
};

export default SubscribedPackges;
