import {
  Container,
  Stack,
  Box,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Loader from './Loader';
import PackageBestSellerCard from './PackageBestSellerCard';
import PackageCard from './PackageCard';
import PackageTypesButtons from './PackgeTypesButtons';
import { useEffect, useState } from 'react';
import { getPackages } from '../app/features/packages/actions';
import { useTranslate, useTranslateByLangId } from '../languages';

interface PackagesListProps {}

const PackagesList: React.FC<PackagesListProps> = ({}) => {
  const t = useTranslate();
  const { loadingPackages, error, packages, userId, langId } = useAppSelector(
    (state) => {
      const lastAction = state.lastAction;
      return {
        error: state.packages.error,
        packages: state.packages.packages,
        userId: state.user.data?.userId,
        langId: state.settings.lang.id,
        loadingPackages:
          state.packages.loading &&
          lastAction.type === getPackages.pending.type,
      };
    }
  );
  const dispatch = useAppDispatch();
  const [languageId, setLanguageId] = useState(2);
  const translate = useTranslateByLangId(languageId);

  useEffect(() => {
    setLanguageId(langId);
  }, [langId]);

  useEffect(() => {
    dispatch(getPackages(languageId));
  }, [languageId]);

  const changeLanguageId = (id: number) => {
    setLanguageId(id);
  };

  return (
    <Container maxW='7xl' pb='200px'>
      <Box>
        <Text
          fontFamily='Gilroy-Bold'
          fontSize={['19px', null, null, null, '40px', '48px']}
        >
          {t('packages-pricing')}
        </Text>
        <Text
          color='rgba(80, 80, 80, 1)'
          fontSize={['12px', null, '18px', null, '23px', '30px']}
          fontFamily='Inter'
        >
          {t('select-suitable-pack')}
        </Text>
      </Box>
      <PackageTypesButtons
        displayOnEnd
        changeLanguage={changeLanguageId}
        currentLanguageId={languageId}
      />
      {loadingPackages ? (
        <Loader size='xl' />
      ) : error ? (
        <Alert>
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <SimpleGrid
          spacing={['16px', null, '20px', '30px', null, '60px']}
          columns={[1, 2, 3]}
          dir={languageId === 1 ? 'rtl' : 'ltr'}
        >
          {packages.map((p) => {
            return p.packageBestSeller ? (
              <PackageBestSellerCard
                key={p.packageId}
                t={translate}
                isRTL={languageId === 1}
                {...p}
              />
            ) : (
              <PackageCard key={p.packageId} t={translate} {...p} />
            );
          })}
        </SimpleGrid>
      )}
    </Container>
  );
};

export default PackagesList;
