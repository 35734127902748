import { Box, Container, List, ListItem, Image, Text } from '@chakra-ui/react';
import mathIcon from '../../../../assests/icons/computing.png';
import volumeIcon from '../../../../assests/icons/volume-high.png';
import indexIcon from '../../../../assests/icons/index.png';
import pauseIcon from '../../../../assests/icons/pause.png';
import { questionContext } from '../../../../contexts/QuestionContextProvider';
import { useContext, useState, useEffect } from 'react';
import { getVoice, useTranslate } from '../../../../languages';
import { secondsToMinutes } from '../../../../util/timer';

interface QuestionHeaderProps {
  counterWidthValue: number;
  toggleQuestionCounter: () => void;
  isStopped: boolean;
  openQuestionIndex: () => void;
}

const QuestionHeader: React.FC<QuestionHeaderProps> = ({
  counterWidthValue,
  toggleQuestionCounter,
  isStopped,
  openQuestionIndex,
}) => {
  const { part, question, currentQuestionIndex, examCounter } =
    useContext(questionContext);
  const [isSpeakOpen, setIsSpeakOpen] = useState(false);
  const t = useTranslate();

  useEffect(() => {
    if (isSpeakOpen) {
      (window as any).responsiveVoice.resume();
      let answers = '';
      if (!question.questionIsDraggable) {
        question.Answer.reduce((prev, cur) => prev + cur.answerText, '');
      }
      if (isSpeakOpen) {
        const voice = (window as any).responsiveVoice;
        console.log({ voice });
        voice.speak(
          question.questiontext + answers,
          getVoice(question.questionLanguageId)
        );
      }
    }
  }, [currentQuestionIndex, isSpeakOpen]);

  const { minutes, seconds } = secondsToMinutes(examCounter);

  return (
    <>
      <Box
        bg={['#09003F']}
        h='100px'
        display={['flex', null, 'none']}
        color='#fff'
        justifyContent='space-between'
        fontFamily='Inter'
        fontWeight='600'
        fontSize='18px'
        alignItems='center'
        px='22px'
      >
        <Text>
          {t('exam-timer')}: {minutes}:{seconds}
        </Text>
        <Text>{t(`part-${part}`)}</Text>
      </Box>
      <Box
        bg={['#407BFF', null, '#09003F']}
        h={['64px', null, null, '70px', null, '100px']}
        display='flex'
        alignItems='center'
      >
        <Container maxW='7xl'>
          <List
            fontFamily='Inter'
            color='#fff'
            display='flex'
            columnGap={['20px', null, '30px']}
            alignItems='center'
            justifyContent={['center', null, 'flex-start']}
          >
            <ListItem
              fontSize='28px'
              display={['none', null, 'block']}
              fontWeight='700'
            >
              {t('helping-tools')}
            </ListItem>
            {part > 1 && (
              <>
                <ListItem
                  cursor='pointer'
                  _hover={{ color: '#f8aa05' }}
                  fontSize={['10px', '12px', null, '16px', null, '20px']}
                  _active={{ color: '#F8AA05' }}
                  display='flex'
                  gap='8px'
                  alignItems='center'
                  flexDirection={['column', null, 'row']}
                  onClick={() => {
                    window.open(
                      '/calculator/index.html',
                      'Calculator',
                      'width=350px, height=550px'
                    );
                  }}
                >
                  <Image src={mathIcon} boxSize={['35px', null, '22px']} />
                  {t('calculator')}
                </ListItem>
                <ListItem
                  cursor='pointer'
                  _hover={{ color: '#f8aa05' }}
                  fontSize={['10px', '12px', null, '16px', null, '20px']}
                  _active={{ color: '#F8AA05' }}
                  display='flex'
                  gap='8px'
                  alignItems='center'
                  flexDirection={['column', null, 'row']}
                  onClick={openQuestionIndex}
                >
                  <Image src={indexIcon} boxSize={['35px', null, '22px']} />
                  {t('q-index')}
                </ListItem>
              </>
            )}
            <ListItem
              cursor='pointer'
              _hover={{ color: '#f8aa05' }}
              fontSize={['10px', '12px', null, '16px', null, '20px']}
              color={isSpeakOpen ? '#f8aa05' : 'inherit'}
              display='flex'
              gap='8px'
              alignItems='center'
              flexDirection={['column', null, 'row']}
              onClick={() => {
                setIsSpeakOpen((val) => !val);
              }}
            >
              <Image src={volumeIcon} boxSize={['35px', null, '22px']} />
              {t('audio-assest')}
            </ListItem>
            <ListItem
              cursor='pointer'
              _hover={{ color: '#f8aa05' }}
              fontSize={['10px', '12px', null, '16px', null, '20px']}
              _active={{ color: '#F8AA05' }}
              color={isStopped ? '#f8aa05' : 'inherit'}
              display='flex'
              gap='8px'
              alignItems='center'
              flexDirection={['column', null, 'row']}
              onClick={toggleQuestionCounter}
            >
              <Image src={pauseIcon} boxSize={['35px', null, '22px']} />
              {t('pause')}
            </ListItem>
          </List>
        </Container>
      </Box>
      {/* question timer */}
      <Box
        bg='#F8AA05'
        h='10px'
        w={`${counterWidthValue}%`}
        transition='width 0.5s ease'
      ></Box>
    </>
  );
};

export default QuestionHeader;
