import './landing.scss';
import LandingTraingJourney from './LandinTrainingJourney';
import LandingHeader from './LandingHeader';
import PackagesList from '../../components/PackagesList';
import ContactForm from '../../components/ContactForm';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

interface LandingProps {}

const Landing: React.FC<LandingProps> = ({}) => {
  return (
    <div className='landing'>
      <Outlet />
      <LandingHeader />
      <LandingTraingJourney />
      <PackagesList />
      <Box mb='170px'>
        <ContactForm />
      </Box>
    </div>
  );
};

export default Landing;
