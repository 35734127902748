import { useAppSelector } from '../app/hooks';
import ar from './ar.json';
import en from './en.json';
import du from './du.json';

interface IIndexable {
  [key: string]: any;
}

const languages: IIndexable = { 1: ar, 2: en, 3: du };

export const langs = [
  {
    id: 1,
    name: 'arabic',
    voice: 'Arabic Male',
  },
  {
    id: 2,
    name: 'english',
    voice: 'US English Male',
  },
  {
    id: 3,
    name: 'dutch',
    voice: 'Dutch Male',
  },
];

export const getVoice = (langId: number) => {
  for (const lang of langs) {
    if (lang.id === langId) return lang.voice;
  }
  return langs[2].voice;
}

export const useTranslate = () => {
  const langId = useAppSelector((state) => {
    return state.settings.lang.id;
  });

  const t = (text: string) => {
    if (!langId) {
      return text;
    }
    if (langId in languages && text in languages[langId]) {
      return languages[langId][text];
    }
    return text;
  };

  return t;
};

export const useTranslateByLangId = (langId: number) => {
  const t = (text: string) => {
    if (!langId) {
      return text;
    }
    if (langId in languages && text in languages[langId]) {
      return languages[langId][text];
    }
    return text;
  };

  return t;
};

export default languages;
