import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { Exam, PartResult, UserAnswer } from '../../../types/index';
import examStatistics from '../../../util/exam-utils';

export const getExamPart = createAsyncThunk(
  'get/exam/part',
  async (
    {
      userId,
      examId,
      departmentId,
      langId,
      isFree,
    }: {
      userId: number;
      examId?: number;
      departmentId: number;
      langId: number;
      isFree: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      if (isFree) {
        const { data } = await api.post(
          `/Admin/Select/Free/Exam/For/User/${langId}/${departmentId}`
        );
        return data.Exam[0];
      } else {
        const res = await api.get<{ Exam: Exam[] }>(
          `/User/Select/Exam/For/User/BY/${userId}/${examId}/${departmentId}`
        );
        return res.data.Exam[0];
      }
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getExamResult = createAsyncThunk(
  'get/exam/result',
  async (
    { userId, examId }: { userId: number; examId: string },
    { rejectWithValue }
  ) => {
    try {
      const part1Res = await api.get<{ UserAnswer: [PartResult] }>(
        `/User/Select/Last/Exam/For/User/${userId}/${examId}/1`
      );
      const part2Res = await api.get<{ UserAnswer: [PartResult] }>(
        `/User/Select/Last/Exam/For/User/${userId}/${examId}/2`
      );
      const part3Res = await api.get<{ UserAnswer: [PartResult] }>(
        `/User/Select/Last/Exam/For/User/${userId}/${examId}/3`
      );

      const part1 = part1Res.data.UserAnswer[0];
      const part2 = part2Res.data.UserAnswer[0];
      const part3 = part3Res.data.UserAnswer[0];

      // console.log({part1, part2, part3});

      const statistics = examStatistics([part1, part2, part3]);

      return {
        parts: [part1, part2, part3],
        ...statistics,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postUserAnswers = createAsyncThunk(
  'exam/post/answers',
  async (
    {
      packageId,
      answers,
      isFree,
    }: { packageId?: number | string; answers: UserAnswer[]; isFree: boolean },
    { rejectWithValue }
  ) => {
    try {
      const url = isFree
        ? `/Admin/Insert/answers/of/Free/exam`
        : `/User/Insert/User/Answers/${packageId}`;

      const userAnswers = answers.map((a) => ({
        userId: a.userId,
        examId: a.examId,
        questionId: a.questionId,
        deptId: a.deptId,
        answerId: a.userAnswers[0],
        answerId2: a.userAnswers[1],
        answerId3: a.userAnswers[2],
      }));
      const { data } = await api.post(url, userAnswers);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
