import { useAppSelector } from '../../app/hooks';
import { useTranslate } from '../../languages';
import './navbar.scss';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobil from './NavbarMobil';

interface NavbarProps {
  bg: string;
  color: string;
}

const Navbar: React.FC<NavbarProps> = ({ bg, color }) => {
  const isLoggedIn = useAppSelector((state) => {
    return state.user.isLoggedIn;
  });
  const t = useTranslate();
  const items = [
    isLoggedIn
      ? {
          text: t('home'),
          to: '/home',
        }
      : {
          text: t('training-journey'),
          to: '',
        },
    {
      text: t('packages-pricing'),
      to: '/packges',
    },
    {
      text: t('about-contact'),
      to: '/about',
    },
  ];
  return (
    <>
      <NavbarDesktop
        isLoggedIn={isLoggedIn}
        items={items}
        bg={bg}
        color={color}
      />
      <NavbarMobil
        isLoggedIn={isLoggedIn}
        items={items}
        backgroundColor='rgba(250, 250, 250, 1)'
      />
    </>
  );
};

export default Navbar;
