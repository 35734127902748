import { Image, Text, chakra } from '@chakra-ui/react';

import image1 from '../../../assests/images/exam/image-1.png';
import image2 from '../../../assests/images/exam/image-2.png';
import { useTranslate } from '../../../languages';
import PartWraper from './PartWraper';

interface PartOneProps {
  goToQuestions: () => void;
}

const PartOne: React.FC<PartOneProps> = ({ goToQuestions }) => {
  const t = useTranslate();
  return (
    <PartWraper
      title={t('part1.title')}
      button={{ text: t('start-exam'), onClick: goToQuestions }}
      part={t('part-1')}
      cards={[
        { text: t('question'), number: 25},
        { text: t('each-one'), number: 8, subText: t('second') },
        { text: t('to-pass'), number: 13, subText: t('question') },
      ]}
      header={
        <>
          <Text
            fontSize={['14px', null, '16px', null, '20px', '20px']}
            fontWeight='600'
            mb='24px'
          >
            {t('exam-overview')[0]}{' '}
            <chakra.span color='#F8AA05'>{t('exam-overview')[1]}</chakra.span>{' '}
            {t('exam-overview')[2]}
          </Text>
          <Text
            fontSize={['14px', null, '16px', null, '20px', '20px']}
            fontWeight='600'
            mb='24px'
          >
            {t('exam-description')[0]}{' '}
            <chakra.span color='#F8AA05'>{t('exam-description')[1]}</chakra.span>
            {t('exam-description')[2]}{' '}
            <chakra.span color='#F8AA05'>{t('exam-description')[3]}</chakra.span>{' '}
            {t('exam-description')[4]}
          </Text>
          <Text fontSize={['14px', null, '16px', null, '20px', '20px']} fontWeight='600'>
            {t('part1.description')}
          </Text>
        </>
      }
    >
      <chakra.picture
        order={[0, 0, 1]}
        w={['100%', null, '40%']}
        maxH={['180px', null, '90vh']}
        h='100%'
      >
        <source media='(min-width: 768px)' srcSet={image1} />
        <source media='(max-width: 768px)' srcSet={image2} />
        <Image src={image1} h={{ base: '200px', md: '100%' }} w='100%' />
      </chakra.picture>
    </PartWraper>
  );
};

export default PartOne;
