import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Colors } from './ExamResult';

interface ExamPartsChartsProps {
  numberOfQuestions: number;
  numberOfCorrectAnswers: number;
  numberOfWrongAnswers: number;
  numberOfUnAnsweredQuestions: number;
}

const ExamPartsCharts: React.FC<ExamPartsChartsProps> = ({
  numberOfCorrectAnswers,
  numberOfQuestions,
  numberOfUnAnsweredQuestions,
  numberOfWrongAnswers,
}) => {
  const correctAnswersPercent = Math.round(
      (numberOfCorrectAnswers / numberOfQuestions) * 100
    ),
    wrongAnswersPercent = Math.round(
      (numberOfWrongAnswers / numberOfQuestions) * 100
    ),
    unAnsweredQuestionsPercent = Math.round(
      (numberOfUnAnsweredQuestions / numberOfQuestions) * 100
    );

  return (
    <div className='charts'>
      <div className='circles'>
        <div className='circle'>
          <CircularProgressbar
            value={correctAnswersPercent}
            maxValue={100}
            background
            styles={buildStyles({
              backgroundColor: '#d2ffbd',
              trailColor: '#d2ffbd',
              textColor: '#09003F',
              textSize: '18px',
              pathColor: '#438624',
              rotation: -0.1,
            })}
            text={numberOfCorrectAnswers.toString()}
          />
        </div>
        <div className='circle'>
          <CircularProgressbar
            value={unAnsweredQuestionsPercent}
            maxValue={100}
            background
            styles={buildStyles({
              backgroundColor: '#C0D4FF',
              trailColor: '#C0D4FF',
              textColor: '#09003F',
              textSize: '18px',
              pathColor: '#4B82FF',
              rotation: -0.1,
            })}
            text={numberOfUnAnsweredQuestions.toString()}
          />
        </div>
        <div className='circle'>
          <CircularProgressbar
            value={wrongAnswersPercent}
            maxValue={100}
            background
            styles={buildStyles({
              backgroundColor: '#FFC0C0',
              trailColor: '#FFC0C0',
              textColor: '#09003F',
              textSize: '18px',
              pathColor: '#C01616',
              rotation: -0.1,
            })}
            text={numberOfWrongAnswers.toString()}
          />
        </div>
      </div>
      <Colors />
    </div>
  );
};

export default ExamPartsCharts;
