import AuthWraper, { Line } from './AuthWraper';
import { Button, Input, Text } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { SignupUserDetails } from '../../types/index';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { signup } from '../../app/features/user/actions';
import useNotification from '../../hooks/notification';
import Loader from '../Loader';
import { useTranslate } from '../../languages';

interface SignupProps {}

const Signup: React.FC<SignupProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector((state) => {
    return {
      loading: state.user.loading,
      error: state.user.error,
    };
  });
  const notify = useNotification();
  const navigate = useNavigate();
  const [user, setUser] = useState<SignupUserDetails>({
    userEmail: '',
    userName: '',
    userPassword: '',
  });
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSignup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (user.userPassword !== repeatedPassword) {
      return notify.info(t('pass-match-err'));
    }
    dispatch(signup(user))
      .then((res) => {
        if (res.type === signup.fulfilled.type) {
          notify.success(t('account-created'));
          navigate('/home');
        }
      })
      .catch(() => {
        notify.error(error);
      });
  };

  return (
    <AuthWraper>
      <Text
        fontFamily='Gilroy-Bold'
        fontSize={['16px', null, null, '20px', null, '32px']}
        mb='15px'
      >
        {t('create-account')}
      </Text>
      <Text
        color='rgba(0, 0, 0, 0.6)'
        fontFamily='Inter'
        fontSize={['10px', '12px', '14px', '16px', null, '20px']}
      >
        {t('create-account-start-journey')}
      </Text>
      <form onSubmit={handleSignup}>
        <Input
          name='userName'
          onChange={handleInputChange}
          variant='flushed'
          placeholder={t('full-name')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['13px', '16px', '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['13px', '16px', '20px'],
          }}
          mb='30px'
        />
        <Input
          name='userEmail'
          onChange={handleInputChange}
          type='email'
          variant='flushed'
          placeholder={t('email')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['13px', '16px', '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['13px', '16px', '20px'],
          }}
          mb='30px'
        />
        <Input
          name='userPassword'
          onChange={handleInputChange}
          type='password'
          variant='flushed'
          placeholder={t('pass')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['13px', '16px', '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['13px', '16px', '20px'],
          }}
          mb='30px'
        />
        <Input
          onChange={(e) => setRepeatedPassword(e.target.value)}
          type='password'
          variant='flushed'
          placeholder={t('confirm-pass')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['13px', '16px', '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['13px', '16px', '20px'],
          }}
          mb='30px'
        />
        <Button
          type='submit'
          fontFamily='Inter'
          borderRadius='0'
          w='85%'
          display='block'
          mx='auto'
          colorScheme='darkBlue'
        >
          {loading ? <Loader size='md' /> : t('signup')}
        </Button>
      </form>
      <Line />
      <Text
        fontFamily='Inter'
        fontSize={['10px', '12px', '14px', '16px', '18px', '20px']}
        textAlign='center'
      >
        {t('already-have-account') + ' '}
        <Link to='/signin' style={{ color: '#F8AA05', fontWeight: 'bold' }}>
          {t('signin')}
        </Link>
      </Text>
    </AuthWraper>
  );
};

export default Signup;
