import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Image,
  SimpleGrid,
  Input,
  Text,
  Button,
  SkeletonCircle,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Loader from '../../components/Loader';
import ProfileSection from './ProfileSection';
import React, { useState, useEffect } from 'react';

import eyeIcon from '../../assests/icons/eye.svg';
import addIcon from '../../assests/icons/plus-btn.svg';
import useNotification from '../../hooks/notification';
import { updateUserData, uploadImage } from '../../app/features/user/actions';
import { BASE_URL } from '../../util/constants';
import defaultUserImage from '../../assests/images/user.png';
import { useTranslate } from '../../languages';

interface EditProfileProps {}

const EditProfile: React.FC<EditProfileProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const { loading, error, userData, loadingImage } = useAppSelector((state) => {
    const lastAction = state.lastAction;
    return {
      loading: state.user.loading,
      error: state.user.error,
      userData: state.user.data,
      loadingImage: state.user.loading, // && lastAction.type === uploadImage.pending.type,
    };
  });
  const [showPassword, setShowPassword] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [updatedUserData, setUpdatedUserData] = useState({
    userName: '',
    userImage: '',
    userEmail: '',
    userPassword: '',
  });

  useEffect(() => {
    setUpdatedUserData((prev) => {
      if (!userData) return prev;
      return {
        userName: userData.userName,
        userImage: userData.userImage,
        userEmail: userData.userEmail,
        userPassword: userData.userPassword,
      };
    });
  }, [userData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSelectFile = (e: React.MouseEvent<HTMLDivElement>) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e: any) => {
      const f = e.target.files[0];
      setFile(f);
      setUpdatedUserData((prev) => {
        return {
          ...prev,
          userImage: URL.createObjectURL(f),
        };
      });
    };
    input.click();
  };

  const handleSaveChanges = () => {
    // save image
    if (file) {
      const formData = new FormData();
      formData.append('Files', file);

      if (userData) {
        dispatch(uploadImage({ data: formData, userId: userData.userId })).then((res) => {
          console.log(res);
          setFile(null);
        });
      }
    }

    if (updatedUserData && userData) {
      dispatch(
        updateUserData({
          userId: userData.userId,
          userEmail: updatedUserData.userEmail,
          userName: updatedUserData.userName,
          userPassword: updatedUserData.userPassword,
        })
      ).then((res) => {
        if (res.type === updateUserData.fulfilled.type) {
          notify.success(t('user-data-updated-success'));
        }
      });
    }
  };

  // const imageUrl = (file ? '' : BASE_URL) + updatedUserData?.userImage;
  const imageUrl = file
    ? updatedUserData?.userImage
    : userData?.userImage
    ? BASE_URL + userData.userImage
    : '';

  return (
    <ProfileSection title={t('edit-profile')}>
      {!userData ? (
        <Alert status='error'>
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <>
          <Flex
            bg='#F5F4F4'
            maxW='470px'
            py='16px'
            px='24px'
            mb='30px'
            alignItems='center'
            justifyContent='space-between'
          >
            {loadingImage ? (
              <SkeletonCircle size='28' />
            ) : (
              <Image
                boxSize={['80px', null, null, '120px']}
                borderRadius='50%'
                src={imageUrl || defaultUserImage}
              />
            )}
            <Box
              color='#09003F'
              fontFamily='Inter'
              fontWeight={500}
              fontSize={['13px', null, null, '16px']}
            >
              <Text
                display='flex'
                alignItems='center'
                cursor='pointer'
                mb='20px'
                onClick={handleSelectFile}
              >
                <Image src={addIcon} me='10px' />
                {t('upload-prof-pic')}
              </Text>
            </Box>
          </Flex>
          <SimpleGrid columns={[1, 1, 2]} spacing='50px'>
            <Input
              name='userName'
              value={updatedUserData.userName}
              onChange={handleInputChange}
              type='text'
              variant='flushed'
              placeholder={t('full-name')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize={['10px', null, null, '20px']}
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: ['10px', null, null, '20px'],
              }}
            />
            <Input
              name='userEmail'
              value={updatedUserData.userEmail}
              onChange={handleInputChange}
              type='email'
              variant='flushed'
              placeholder={t('email')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize={['10px', null, null, '20px']}
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: ['10px', null, null, '20px'],
              }}
            />
            <InputGroup>
              <Input
                name='userPassword'
                value={updatedUserData.userPassword}
                onChange={handleInputChange}
                type={showPassword ? 'text' : 'password'}
                variant='flushed'
                placeholder={t('pass')}
                fontFamily='Inter'
                fontWeight='500'
                fontSize={['10px', null, null, '20px']}
                color='rgba(0, 0, 0, 1)'
                _placeholder={{
                  color: 'rgba(0, 0, 0, 1)',
                  fontSize: ['10px', null, null, '20px'],
                }}
              />
              {document.dir == 'rtl' ? (
                <InputLeftElement>
                  <Image
                    src={eyeIcon}
                    boxSize='20px'
                    cursor='pointer'
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                </InputLeftElement>
              ) : (
                <InputRightElement>
                  <Image
                    src={eyeIcon}
                    boxSize='20px'
                    cursor='pointer'
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </SimpleGrid>
          <Button
            bg='#505050'
            color='#fff'
            colorScheme='darkBlue'
            px='20px'
            pos='absolute'
            bottom='30px'
            right='30px'
            borderRadius={0}
            fontFamily='Inter'
            fontWeight={600}
            fontSize={['10px', null, '12px', null, '16px', null, '18px']}
            onClick={handleSaveChanges}
          >
            {loading ? <Loader size='md' /> : t('save')}
          </Button>
        </>
      )}
    </ProfileSection>
  );
};

export default EditProfile;
