import { Box, Text, Image, Divider } from '@chakra-ui/react';

interface CardWrapperProps {
  title: string;
  icon: string;
  children: React.ReactNode;
}

const CardWrapper: React.FC<CardWrapperProps> = ({ title, children, icon }) => {
  return (
    <Box bg='#fff' p='12px 12px 30px' h='100%'>
      <Text
        display='flex'
        alignItems='center'
        color='#000'
        fontFamily='Inter'
        fontWeight={600}
        fontSize={['16px', null, null, '20px']}
      >
        <Image boxSize='20px' src={icon} me='10px' />
        {title}
      </Text>
      <Divider borderBottomWidth={3} mt='10px' mb='20px' />
      {children}
    </Box>
  );
};

export default CardWrapper;
