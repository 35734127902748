import {
  Container,
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Spacer,
  Button,
  Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Logo from '../Logo';
import { LoggedInUserMenu, UnLoggedInUserMenu } from './UserMenu';

interface NavbarDesktopProps {
  bg: string;
  color?: string;
  isLoggedIn: boolean;
  items: {
    text: string;
    to: string;
  }[];
}

const NavbarDesktop: React.FC<NavbarDesktopProps> = ({
  bg,
  isLoggedIn,
  items,
  color,
}) => {
  return (
    <Box
      bg={bg}
      display={{ base: 'none', lg: 'block' }}
      py={['10px', null, null, null, null, '15px']}
    >
      <Container maxW='7xl'>
        <HStack justifyContent='space-between'>
          <Logo subTitleStyle={{ color }} />
          <List display='flex' columnGap='2rem'>
            {items.map((item) => (
              <ListItem
                as={NavLink}
                color={color}
                to={item.to}
                key={item.text}
                fontSize='lg'
                fontWeight='600'
                fontFamily='Inter'
                pos='relative'
                sx={{
                  '&.active': {
                    color: bg === 'blue.main' ? 'gray.200': 'blue.main',
                  },
                  '&.active::before, &:hover::before': {
                    content: '""',
                    pos: 'absolute',
                    left: 0,
                    bottom: '-2',
                    w: '100%',
                    h: '3px',
                    bg: bg === 'blue.main' ? 'gray.200': 'blue.main',
                  },
                }}
              >
                {item.text}
              </ListItem>
            ))}
          </List>
          {isLoggedIn ? <LoggedInUserMenu /> : <UnLoggedInUserMenu />}
        </HStack>
      </Container>
    </Box>
  );
};

export default NavbarDesktop;
