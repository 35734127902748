import {
  Box,
  Divider,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useTranslate } from '../../../languages';
import { QuestionResult } from '../../../types';
import { AnswerCard } from '../part/question/MultiChoiceQuestion';
import CanvasImage from './CanvasImage';
import { getAnswerType } from '../../../util/exam-utils';

interface QuestionResultModalContentProps {
  show: boolean;
  onClose: () => void;
  question: QuestionResult;
}

const QuestionResultModalContent: React.FC<QuestionResultModalContentProps> = ({
  show,
  onClose,
  question,
}) => {
  const t = useTranslate();

  return (
    <ModalContent my='1rem'>
      <ModalHeader mb='.5rem'>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {!question.questionIsDraggable && (
          <Image src={question.questionImage} maxH='400px' w='100%' />
        )}
        <Text fontSize='1rem' my='1rem'>
          {question.questiontext}
        </Text>
        {question.questionIsDraggable ? (
          <>
            <Box mb='1rem'>
              <Text>{t('correct-answers')}</Text>
              <CanvasImage
                pointsStr={question.Answer[0].answerText}
                imageUrl={question.questionImage}
              />
            </Box>
            {question.Answer[0].answerText !==
              question.Answer[0].UserAnswer[0].answerId && (
              <Box>
                <Text>{t('wrong-answers')}</Text>
                <CanvasImage
                  pointsStr={question.Answer[0].UserAnswer[0].answerId}
                  imageUrl={question.questionImage}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            <SimpleGrid columns={[1]} rowGap='1rem' columnGap='.6rem'>
              {question.Answer.map((answer) => (
                <AnswerCard
                  key={answer.answerId}
                  className={
                    [
                      question.questionCorrectAnswerId,
                      question.questionCorrectAnswerId2,
                      question.questionCorrectAnswerId3,
                    ].includes(answer.answerId)
                      ? 'correct'
                      : [
                          question.Answer?.[0].UserAnswer?.[0].answerId,
                          question.Answer?.[0].UserAnswer?.[0].answerId2,
                          question.Answer?.[0].UserAnswer?.[0].answerId3,
                        ].includes(answer.answerId.toString())
                      ? 'wrong'
                      : ''
                  }
                >
                  {t(getAnswerType(question, answer.answerId.toString()))}{' '}
                  {answer.answerText}
                </AnswerCard>
              ))}
            </SimpleGrid>
          </>
        )}
        <Divider borderWidth='2px' />
        <Text fontSize='1rem' fontWeight={500} my='.5rem'>
          {question.questionReasone}
        </Text>
      </ModalBody>
    </ModalContent>
  );
};

export default QuestionResultModalContent;
