import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useNotification from '../../hooks/notification';
import AuthWraper from './AuthWraper';
import { Alert, AlertIcon, Text, Input, Button } from '@chakra-ui/react';
import Loader from '../Loader';
import { useState } from 'react';
import { sendEmail } from '../../app/features/user/actions';
import { useTranslate } from '../../languages';

interface ForgetPasswordProps {}

const ForgetPassword: React.FC<ForgetPasswordProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const { loading, error } = useAppSelector((state) => {
    return {
      loading: state.user.loading,
      error: state.user.error,
    };
  });
  const [email, setEmail] = useState('null');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      return notify.error(t('plz-enter-valid-email'));
    }
    dispatch(sendEmail(email)).then((res) => {
      if (res.type === sendEmail.fulfilled.type) {
        notify.success(res.payload as string);
      } else {
        notify.error(res.payload as string);
      }
    });
  };

  return (
    <AuthWraper>
      {error && (
        <Alert status='error'>
          <AlertIcon />
          {error}
        </Alert>
      )}
      <Text
        fontFamily='Gilroy-Bold'
        fontSize={['16px', null, null, '20px', null, '32px']}
        mb='15px'
      >
        {t('forget-pass')}
      </Text>
      <form onSubmit={handleSendEmail}>
        <Input
          name='userEmail'
          onChange={handleInputChange}
          type='email'
          variant='flushed'
          placeholder={t('email')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['10px', null, null, '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['10px', null, null, '20px'],
          }}
          mb='30px'
        />
        <Button
          type='submit'
          fontFamily='Inter'
          borderRadius='0'
          w='85%'
          display='block'
          mx='auto'
          colorScheme='darkBlue'
        >
          {loading ? <Loader size='md' /> : t('send-email')}
        </Button>
      </form>
    </AuthWraper>
  );
};

export default ForgetPassword;

const isValidEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return re.test(email.toLowerCase());
};
