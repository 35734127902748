import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { useAppSelector } from '../../../../app/hooks';
import rightIcon from '../../../../assests/icons/right.png';
import leftIcon from '../../../../assests/icons/left.png';
import { useTranslate } from '../../../../languages';
import { questionContext } from '../../../../contexts/QuestionContextProvider';
import { useContext } from 'react';

interface QuestionControlsProps {
  next: () => void;
}

const QuestionControls: React.FC<QuestionControlsProps> = ({ next }) => {
  const t = useTranslate();

  const { prevQuestion, currentQuestionIndex, part, examId, examName } =
    useContext(questionContext);

  return (
    <HStack justifyContent='space-between' mb='50px'>
      {part > 1 && currentQuestionIndex > 0 && (
        <Button
          color='#09003F'
          bg='transparent'
          fontFamily='Inter'
          fontWeight='600'
          fontSize={['13px', null, '15px', null, '16px', '18px']}
          borderRadius='0'
          _hover={{ bg: '#09003F', color: '#fff' }}
          leftIcon={
            <img
              src={leftIcon}
              style={{
                transform: document.dir == 'rtl' ? 'rotate(180deg)' : '',
              }}
            />
          }
          onClick={prevQuestion}
        >
          {t('previous')}
        </Button>
      )}
      <Text
        color='#656565'
        fontFamily='Inter'
        fontWeight='600'
        fontSize={['10px', null, '13px', null, '18px', '20px']}
      >
        {examName} / {t('question')}{' '}
        {(part === 1 ? 0 : part === 2 ? 12 : 36) + currentQuestionIndex + 1}
        /65
      </Text>
      <Button
        colorScheme='darkBlue'
        px='25px'
        fontFamily='Inter'
        fontWeight='600'
        fontSize={['13px', null, '15px', null, '16px', '18px']}
        borderRadius='0'
        rightIcon={
          <img
            src={rightIcon}
            style={{ transform: document.dir == 'rtl' ? 'rotate(180deg)' : '' }}
          />
        }
        onClick={next}
      >
        {t('next')}
      </Button>
    </HStack>
  );
};

export default QuestionControls;
