import { createContext } from 'react';
import { Question, UserAnswer } from '../types';

interface QuestionContextProps {
  part: number;
  nextQuestion: () => void;
  prevQuestion: () => void;
  addAnswer: (answer: UserAnswer) => void;
  deleteAnswer: (answer: UserAnswer) => void;
  editAnswer: (answer: UserAnswer) => void;
  getUserQuestionAnswer: (questionId: number, partId: number) => UserAnswer;
  goToQuestion: (index: number) => void;
  question: Question;
  examId?: number;
  userId: number;
  currentQuestionIndex: number;
  examCounter: number;
  examName?: string;
}

export const questionContext = createContext<QuestionContextProps>({} as QuestionContextProps);

interface QuestionContextProviderProps {
  children: JSX.Element;
  value: QuestionContextProps;
}

const QuestionContextProvider: React.FC<QuestionContextProviderProps> = ({
  children,
  value,
}) => {
  return <questionContext.Provider value={value}>{children}</questionContext.Provider>;
};

export default QuestionContextProvider;
