import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Exam, ExamResult, PartResult } from '../../../types';
import { DEFAULT_ERROR_MSG } from '../../../util/constants';
import { getExamPart, getExamResult, postUserAnswers } from './actions';

interface InitialState {
  loading: boolean;
  error: string;
  data: Exam | null;
  result: {
    parts: ExamResult;
    percentage: number;
    numberOfCorrectAnswers: number;
    numberOfQuestions: number;
    numberOfUnAnsweredQuestions: number;
    numberOfWrongAnswers: number;
  };
}

const initialState: InitialState = {
  loading: false,
  error: '',
  data: null,
  result: {
    parts: [],
    percentage: 0,
    numberOfCorrectAnswers: 0,
    numberOfQuestions: 0,
    numberOfUnAnsweredQuestions: 0,
    numberOfWrongAnswers: 0,
  },
};

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    toggleQuestionMark: (state, action: PayloadAction<number>) => {
      const index = state.data?.Question.findIndex(
        (q) => q.questionId === action.payload
      );
      if (index !== undefined && state.data) {
        state.data.Question[index].isMarked = !state.data.Question[index].isMarked;
      }
    },
  },
  extraReducers: (builder) => {
    // get exam part
    builder.addCase(getExamPart.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getExamPart.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload;
    });
    builder.addCase(getExamPart.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // get exam result
    builder.addCase(getExamResult.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getExamResult.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.result = action.payload;
    });
    builder.addCase(getExamResult.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // post user answers
    builder.addCase(postUserAnswers.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(postUserAnswers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(postUserAnswers.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });
  },
});

export default examSlice.reducer;
export const { toggleQuestionMark } = examSlice.actions;
