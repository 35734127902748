import { chakra } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

interface Point {
  x: number;
  y: number;
  id: number;
}

interface CanvasImageProps {
  pointsStr: string;
  imageUrl: string;
}

const CanvasImage: React.FC<CanvasImageProps> = ({ pointsStr, imageUrl }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    try {
      const points: Point[] = JSON.parse(pointsStr);
      const canvas = canvasRef.current,
        ctx = canvas?.getContext('2d');
      if (ctx && canvas) {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          showCircles(points, ctx, canvas.width, canvas.height);
        };
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return <chakra.canvas w='100%' maxH='400px' ref={canvasRef}></chakra.canvas>;
};

export default CanvasImage;

const showCircles = (
  coordinates: { x: number; y: number; id: number }[],
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
) => {
  for (let item of coordinates) {
    ctx.beginPath();
    ctx.fillStyle = '#407BFF';
    const x = Math.ceil((item.x / 100) * width),
      y = Math.ceil((item.y / 100) * height);
    ctx.arc(x, y, 12, 0, 2 * Math.PI);
    ctx.fill();
    // ctx.stroke();
    ctx.fillStyle = '#fff';
    ctx.font = '.8rem Inter';
    ctx.fillText(item.id.toString(), x - 3, y + 3);
  }
};
