import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWebsiteContentData } from '../../../types';
import api from '../../api';

interface IWebisteContent {
  contactUsId: number;
  phoneNumber: string;
  email: string;
  address: string;
  footerArabicText?: string;
  footerEnglishText?: string;
  footerDushText?: string;
  aboutUsArabicText?: string;
  aboutUsEnglishText?: string;
  aboutUsDushText?: string;
}

export const getWebsiteContent = createAsyncThunk(
  'get/website/content',
  async (langId: number, { rejectWithValue }) => {
    try {
      const { data } = await api.get<IWebisteContent>(
        `/User/Select/Footer/Site/And/ContactUs/${langId}`
      );
      return {
        email: data.email,
        address: data.address,
        pohone: data.phoneNumber,
        footer:
          data.footerArabicText ??
          data.footerDushText ??
          data.footerEnglishText,
        about:
          data.aboutUsArabicText ??
          data.aboutUsDushText ??
          data.aboutUsEnglishText,
      } as IWebsiteContentData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
