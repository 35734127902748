import { getPackages } from './actions';
import { DEFAULT_ERROR_MSG } from '../../../util/constants';
import { createSlice } from '@reduxjs/toolkit';
import { Package } from '../../../types';

interface InitialState {
  loading: boolean;
  error: string;
  packages: Package[]
}

const initialState: InitialState = {
  loading: false,
  error: '',
  packages: []
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackages.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.packages = action.payload;
    });
    builder.addCase(getPackages.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });
  },
});

export default packagesSlice.reducer;
export const { } = packagesSlice.actions;