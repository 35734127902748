import {
  Box,
  Button,
  List,
  ListItem,
  Text,
  UnorderedList,
  chakra,
} from '@chakra-ui/react';
import { useTranslate } from '../languages';
import { Package } from '../types';
import BuyPackage from './BuyPackage';

interface PackageCardProps extends Package {
  t: (text: string) => string;
}

const PackageCard: React.FC<PackageCardProps> = ({
  packageDuration,
  packageExamLanguage,
  packageId,
  packageOfferPrice,
  packageText,
  packagePrice,
  examCount,
  examReapetCount,
  t,
}) => {

  return (
    <Box
      bg='gray.main'
      px='20px'
      py='40px'
      boxShadow=' 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)'
    >
      <Text
        fontFamily='Gilroy-Bold'
        fontSize={['30px', null, null, null, '36px', '56px']}
        mb={['23px']}
      >
        {packagePrice} €{' '}
        <chakra.sub
          fontFamily='Gilroy-SemiBold'
          fontSize={['12px', null, null, '15px', null, '24px']}
          color='rgba(0, 0, 0, 0.5)'
        >
          /{packageDuration} {t('days')}
        </chakra.sub>
      </Text>
      <Text
        color='rgba(0, 0, 0, 1)'
        fontFamily='Gilroy-Bold'
        fontSize={['26px', null, null, null, '22px', '32px']}
        mb={['36px']}
      >
        {packageText}
      </Text>
      <UnorderedList
        ps='10px'
        fontSize={['12px', null, '16px', null, '20px', '24px']}
        fontFamily='Inter'
      >
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          {t('language')}
          <chakra.span fontWeight='bold'> {packageExamLanguage}</chakra.span>
        </ListItem>
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          <chakra.span fontWeight='bold'>{examCount * 45} </chakra.span>
          {t('theory-questions')}
        </ListItem>
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          <chakra.span fontWeight='bold'>{examReapetCount} </chakra.span>
          {t('repetition-for-exam')}
        </ListItem>
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          <chakra.span fontWeight='bold'>{examCount} </chakra.span>
          {t('theory-exams')}
        </ListItem>
      </UnorderedList>
      <BuyPackage btnText={t('start-training')} packageId={packageId} />
    </Box>
  );
};

export default PackageCard;
