import {
  Container,
  HStack,
  Box,
  Text,
  Image,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import PackagesList from '../../components/PackagesList';
import img from '../../assests/images/home.png';
import { useTranslate } from '../../languages';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import useNotification from '../../hooks/notification';

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const t = useTranslate();
  const [search, setSearch] = useSearchParams();

  const status = search.get('status'),
    msg = search.get('msg');

  return (
    <Box bg='#E5E5E5'>
      <Container maxW='7xl' py='50px'>
        {status && (
          <Alert status={status === 'success' ? 'success' : 'error'} mb='1rem'>
            <AlertIcon />
            <Box>
              <AlertTitle>{status}</AlertTitle>
              <AlertDescription>
                {msg ||
                  (status === 'success'
                    ? t('payment.success')
                    : t('payment.fail'))}
              </AlertDescription>
            </Box>
          </Alert>
        )}
        <HStack bg='#fff' display={['none', null, 'flex']}>
          <Box w={['100%', null, '40%']} ps='15px' py='40px'>
            <Text
              fontFamily='Gilroy-Bold'
              fontSize={['19px', null, '22px', null, '30px', '35px']}
              // mb='15px'
            >
              {t('packages-pricing')}
            </Text>
            <Text
              color='#505050'
              fontSize={['12px', '13px', '16px', '20px', '22px']}
            >
              {t('select-suitable-pack')}
            </Text>
          </Box>
          <Image
            src={img}
            w='60%'
            h='200px'
            clipPath={
              document.dir === 'rtl'
                ? 'polygon(0 0, 86% 0, 100% 100%, 100% 100%, 80% 100%, 20% 100%, 0 100%, 0% 20%)'
                : 'polygon(16% 0, 80% 0%, 100% 0, 100% 100%, 80% 100%, 20% 100%, 0 100%, 0 100%)'
            }
          />
        </HStack>
      </Container>
      <PackagesList />
    </Box>
  );
};

export default Home;
