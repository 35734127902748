import { ExamResult, PartResult, Question, QuestionResult } from '../types';

type StringUndefinedArray = (string | undefined)[];

export const getQuestionCorrectAnswersCount = (q: Question) => {
  let count = 0;
  if (q.questionCorrectAnswerId) count++;
  if (q.questionCorrectAnswerId2) count++;
  if (q.questionCorrectAnswerId3) count++;
  return count;
};

export const arraysEqual = (
  arr1: StringUndefinedArray,
  arr2: StringUndefinedArray
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const checkUserAnswer = (question: QuestionResult) => {
  if (question.questionIsDraggable) {
    const answer = question.Answer[0];
    return answer.answerText === answer.UserAnswer?.[0].answerId
      ? 'correct'
      : answer.UserAnswer?.[0].answerId
      ? 'wrong'
      : '';
  }

  const correctAnswers = [
    question.questionCorrectAnswerId?.toString(),
    question.questionCorrectAnswerId2?.toString(),
    question.questionCorrectAnswerId3?.toString(),
  ];
  correctAnswers.sort();

  const userAnswers = [
    question.Answer?.[0].UserAnswer?.[0].answerId,
    question.Answer?.[0].UserAnswer?.[0].answerId2,
    question.Answer?.[0].UserAnswer?.[0].answerId3,
  ];
  userAnswers.sort();

  return arraysEqual(correctAnswers, userAnswers)
    ? 'correct'
    : question.Answer?.[0].UserAnswer?.[0].answerId
    ? 'wrong'
    : '';
};

const examStatistics = (parts: PartResult[]) => {
  let numberOfCorrectAnswers = 0,
    numberOfQuestions = 0,
    numberOfUnAnsweredQuestions = 0,
    numberOfWrongAnswers = 0;

  for (const part of parts) {
    for (const q of part.Question) {
      numberOfQuestions++;
      if (!q.questionCorrectAnswerId) {
        continue;
      }

      const result = checkUserAnswer(q);
      if (result === 'correct') {
        numberOfCorrectAnswers++;
      } else if (result === 'wrong') {
        numberOfWrongAnswers++;
      } else {
        numberOfUnAnsweredQuestions++;
      }
    }
  }

  return {
    percentage: Math.round((numberOfCorrectAnswers / numberOfQuestions) * 100),
    numberOfCorrectAnswers,
    numberOfQuestions,
    numberOfUnAnsweredQuestions,
    numberOfWrongAnswers,
  };
};

export default examStatistics;

export const getAnswerType = (question: QuestionResult, answerId?: string) => {
  const correctAnswers = [
    question.questionCorrectAnswerId?.toString(),
    question.questionCorrectAnswerId2?.toString(),
    question.questionCorrectAnswerId3?.toString(),
  ];

  const userAnswers = [
    question.Answer?.[0].UserAnswer?.[0].answerId,
    question.Answer?.[0].UserAnswer?.[0].answerId2,
    question.Answer?.[0].UserAnswer?.[0].answerId3,
  ];

  return correctAnswers.includes(answerId)
    ? 'correct-answer'
    : userAnswers.includes(answerId)
    ? 'user-answer'
    : '';
};
