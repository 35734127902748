import {
  HStack,
  Image,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import image from '../../assests/images/auth.png';
import styled from 'styled-components';  

interface AuthWraperProps {
  children: React.ReactNode;
}

const AuthWraper: React.FC<AuthWraperProps> = ({ children: content }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={true}
      onClose={() => {
        navigate('/');
      }}
    >
      <ModalOverlay />
      <ModalContent maxW='5xl'>
        <HStack>
          <Image
            src={image}
            w='50%'
            maxH='600px'
            display={['none', null, null, 'block']}
          />
          <Box
            w={['100%', null, null, '50%']}
            boxShadow='103px 0px 41px rgba(230, 230, 230, 0.01), 58px 0px 35px rgba(230, 230, 230, 0.05), 26px 0px 26px rgba(230, 230, 230, 0.09), 6px 0px 14px rgba(230, 230, 230, 0.1), 0px 0px 0px rgba(230, 230, 230, 0.1)'
            p={['45px']}
          >
            {content}
          </Box>
        </HStack>
      </ModalContent>
    </Modal>
  );
};

export default AuthWraper;

export const Line = styled.div`
  height: 0px;
  border: 1px solid #f8aa05;
  display: block;
  margin: 30px auto 15px auto;
`;