import { Box, Image, Text, chakra } from '@chakra-ui/react';

import image1 from '../../../assests/images/exam/part3-lg.png';
import image2 from '../../../assests/images/exam/part3-sm.png';
import { useTranslate } from '../../../languages';
import PartWraper from './PartWraper';

interface PartThreeProps {
  goToQuestions: () => void;
}

const PartThree: React.FC<PartThreeProps> = ({ goToQuestions }) => {
  const t = useTranslate();
  return (
    <Box>
      <PartWraper
        title={t('part3.title')}
        button={{ text: t('start-exam'), onClick: goToQuestions }}
        part={t('part-3')}
        cards={[
          { text: t('questions'), number: 28 },
          { text: t('each-one'), number: 40 },
          { text: t('to-pass'), number: 25 },
        ]}
        text={t('part3.description')}
      >
        <chakra.picture
          order={[0, 0, 1]}
          w={['100%', null, '40%']}
          maxH={['180px', null, '100vh']}
        >
          <source media='(min-width: 768px)' srcSet={image1} />
          <source media='(max-width: 768px)' srcSet={image2} />
          <Image src={image1} h={{ base: '200px', md: '100%' }} w='100%' />
        </chakra.picture>
      </PartWraper>
    </Box>
  );
};

export default PartThree;
