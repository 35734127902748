import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  MenuButtonProps,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useTranslate } from '../languages';
import polygonIcon from '../assests/icons/header/polygon.svg';
import { langs } from '../languages';
import { changeLanguage } from '../app/features/settings/slice';

interface LanguagesListProps extends MenuButtonProps {}

const LanguagesList: React.FC<LanguagesListProps> = ({ ...props }) => {
  const t = useTranslate();
  const lang = useAppSelector((state) => state.settings.lang);
  const dispatch = useAppDispatch();
  return (
    <Menu>
      <MenuButton color='white' display='flex' alignItems='center' {...props}>
        {t(lang.name)}
        <Image src={polygonIcon} boxSize='10px' ms='5px' display='inline' />
      </MenuButton>
      <MenuList>
        {langs.map((l) => (
          <MenuItem
            color={'#000'}
            key={l.id}
            onClick={() => {
              dispatch(changeLanguage(l));
            }}
          >
            {t(l.name)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguagesList;
