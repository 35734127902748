import { useToast } from '@chakra-ui/react';

const useNotification = () => {
  const toast = useToast();
  return {
    success: (title: string, msg?: string) =>
      toast({
        title,
        description: msg,
        status: 'success',
        duration: 4000,
        isClosable: false,
        position: document.dir == 'rtl' ? 'top-left' : 'top-right',
      }),
    error: (title: string, msg?: string) =>
      toast({
        title,
        description: msg,
        status: 'error',
        duration: 4000,
        isClosable: false,
        position: document.dir == 'rtl' ? 'top-left' : 'top-right',
      }),
    info: (title: string, msg?: string) =>
      toast({
        title,
        description: msg,
        status: 'info',
        duration: 4000,
        isClosable: false,
        position: document.dir == 'rtl' ? 'top-left' : 'top-right',
      }),
  };
};

export default useNotification;
