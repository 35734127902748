import './styles/main.scss';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Landing from './pages/landing/Landing';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './pages/profile/Profile';
import Signup from './components/auth/Signup';
import LoginByEmail from './components/auth/LoginByEmail';
import PackgesAndPricing from './pages/packges-pricing/PackgesAndPricing';
import AboutUs from './pages/about';
import SubscribedPackges from './pages/profile/SubscribedPackages';
import EditProfile from './pages/profile/EditProfile';
import ExamsList from './pages/profile/ExamsList';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/home/Home';
import { loginUsingSession } from './app/features/user/slice';
import Exam from './pages/exam/Exam';
import { setSettings } from './app/features/settings/slice';
import PageWrapper from './pages/PageWrapper';
import NavbarWrapper from './pages/NavbarWrapper';
import ExamResult from './pages/exam/result/ExamResult';
import {
  getSettingsFromLocalStorage,
  getUserFromSessionStorage,
} from './util/local-session-storage';
import ForgetPassword from './components/auth/ForgetPassword';
import { getWebsiteContent } from './app/features/settings/actions';
import LoginByCode from './components/auth/LoginByCode';
import { ErrorBoundary } from './components/error-boundary/ErrorBoundary';

const App = () => {
  const dispatch = useAppDispatch();
  const { langId } = useAppSelector((state) => {
    return {
      langId: state.settings.lang.id,
    };
  });
  useEffect(() => {
    const user = getUserFromSessionStorage();
    const settings = getSettingsFromLocalStorage();
    if (user) {
      dispatch(loginUsingSession(user));
    }

    if (settings) {
      dispatch(setSettings(settings));
    }
  }, []);

  useEffect(() => {
    dispatch(getWebsiteContent(langId));
  }, [langId]);

  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route element={<PageWrapper />}>
            <Route path='/' element={<Landing />}>
              <Route path='signup' element={<Signup />} />
              <Route path='signin' element={<LoginByEmail />} />
              <Route path='signin/code' element={<LoginByCode />} />
              <Route path='forget-password' element={<ForgetPassword />} />
            </Route>

            <Route path='/packges' element={<PackgesAndPricing />} />
            <Route path='/about' element={<AboutUs />} />
          </Route>
          <Route element={<NavbarWrapper />}>
            <Route
              path='/profile'
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            >
              <Route index element={<SubscribedPackges />} />
              <Route path='edit' element={<EditProfile />} />
              <Route path='suscribed-packs' element={<SubscribedPackges />} />
              <Route
                path='suscribed-packs/:packageId/exams-list'
                element={<ExamsList />}
              />
            </Route>
            <Route
              path='/home'
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path='/package/:packageId/exam/:examId/result'
              element={<ExamResult />}
            />
          </Route>
          <Route
            path='/package/:packageId/exam/:examId'
            element={<Exam isFree={false} />}
          />
          <Route path='/free-exam' element={<Exam isFree />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
