import { Box, Text, Divider } from '@chakra-ui/react';

interface ProfileSectionProps {
  title: string;
  showBackArrow?: boolean;
  children: React.ReactNode;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  title,
  children,
}) => {
  return (
    <Box bg='#fff' w={['100%', null, '75%']} p='20px' minH='90vh' pos='relative'>
      <Text
        fontFamily='Inter'
        fontWeight={600}
        fontSize={['18px', '20px', '25px', '30px', '35px', '40px']}
      >
        {title}
      </Text>
      <Divider borderBottomWidth='3px' my='10px' />
      {children}
    </Box>
  );
};

export default ProfileSection;
