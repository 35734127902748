import { Link, useParams, useSearchParams } from 'react-router-dom';
import './exam-result.scss';
import {
  Alert,
  AlertIcon,
  Button,
  Container,
  HStack,
  Modal,
  ModalOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ExamPartsCharts from './ExamPartsCharts';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useEffect } from 'react';
import { getExamResult } from '../../../app/features/exam/actions';
import { QuestionResult } from '../../../types';
import { useTranslate } from '../../../languages';
import QuestionResultModalContent from './QuestionResultModal';
import { arraysEqual, checkUserAnswer } from '../../../util/exam-utils';

interface ExamResultProps {}

const ExamResult: React.FC<ExamResultProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const { examId, packageId } = useParams();
  const { loading, error, result, userId } = useAppSelector((state) => {
    return {
      loading: !state.exam.loading,
      error: state.exam.error,
      result: state.exam.result,
      userId: state.user.data?.userId,
    };
  });

  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (examId && userId) {
      dispatch(getExamResult({ userId: userId, examId }));
    }
  }, [examId, userId]);

  const questionIndex = search.get('questionIndex'),
    partIndex = search.get('partIndex'),
    mode = search.get('mode');

  let question = null;

  if (mode === 'view' && questionIndex !== null && partIndex !== null) {
    question = result.parts[+partIndex]?.Question[+questionIndex];
  }

  return (
    <>
      <Modal onClose={() => setSearch({})} isOpen={!!question}>
        <ModalOverlay />
        <QuestionResultModalContent
          onClose={() => setSearch({})}
          show={true}
          question={question!}
        />
      </Modal>
      <div className='exam-result' key={Math.random()}>
        <Container maxW='7xl'>
          {error ? (
            <Alert status='error'>
              <AlertIcon />
              {error}
            </Alert>
          ) : (
            <>
              <div className='recap'>
                <Skeleton isLoaded={loading} mb='1rem'>
                  <div className='info'>
                    <h2 className='title'>
                      {t(
                        result.percentage >= 50
                          ? 'pass-exam-msg'
                          : 'fail-in-exam-msg'
                      )}
                    </h2>
                    <h3
                      className={
                        'subtitle ' +
                        (result.percentage >= 50 ? 'success' : 'fail')
                      }
                    >
                      {t('with-precent')} : {result.percentage}%
                    </h3>
                    <HStack spacing={5}>
                      <Button
                        as={Link}
                        to='/home'
                        colorScheme='darkBlue'
                        px='30px'
                        borderRadius='0'
                      >
                        {t('go-home')}
                      </Button>
                      <Text
                        as={Link}
                        to={`/package/${packageId}/exam/${examId}`}
                        display='inline'
                        color='#09003F'
                        fontFamily='Inter'
                        fontWeight='600'
                        fontSize={['12px', '14px', null, '16px', null, '18px']}
                      >
                        {t('try-exam-again')}
                      </Text>
                      <Text
                        as={Link}
                        to={`/profile/suscribed-packs/${packageId}/exams-list`}
                        display='inline'
                        color='#09003F'
                        fontFamily='Inter'
                        fontWeight='600'
                        fontSize={['12px', '14px', null, '16px', null, '18px']}
                      >
                        {t('go-to-exams')}
                      </Text>
                    </HStack>
                  </div>
                </Skeleton>
                <ExamPartsCharts
                  numberOfQuestions={result.numberOfQuestions}
                  numberOfCorrectAnswers={result.numberOfCorrectAnswers}
                  numberOfUnAnsweredQuestions={
                    result.numberOfUnAnsweredQuestions
                  }
                  numberOfWrongAnswers={result.numberOfWrongAnswers}
                />
              </div>
              <div className='results'>
                {!loading
                  ? [1, 2, 3].map((part, index) => (
                      <div className='part' key={index}>
                        <div className='header'>
                          <div className='title'>{t(`part-${index + 1}`)}</div>
                          <Colors />
                        </div>
                        <div className='cards'>
                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                          ].map((item) => (
                            <Skeleton isLoaded={loading} key={item}>
                              <div className='card'>{item}</div>
                            </Skeleton>
                          ))}
                        </div>
                      </div>
                    ))
                  : result.parts.map((part, partIndex) => (
                      <div className='part' key={partIndex}>
                        <div className='header'>
                          <div className='title'>
                            {t(`part-${partIndex + 1}`)}
                          </div>
                          <Colors />
                        </div>
                        <div className='cards'>
                          {part.Question.map((question, questionIndex) => (
                            <div
                              key={question.questionId}
                              className={'card ' + checkUserAnswer(question)}
                              onClick={() => {
                                setSearch({
                                  partIndex: partIndex.toString(),
                                  questionIndex: questionIndex.toString(),
                                  mode: 'view',
                                });
                              }}
                            >
                              {questionIndex + 1}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ExamResult;

const Color = styled.span`
  font-family: Inter;
  font-weight: 500;
  font-size: 10px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
    background-color: ${(props) => props.color};
  }
`;

export const Colors = () => {
  const t = useTranslate();

  return (
    <div className='colors'>
      <Color color='#438624'>{t('correct-answers')}</Color>
      <Color color='#4B82FF'>{t('unanswered')}</Color>
      <Color color='#C01616'>{t('wrong-answers')}</Color>
    </div>
  );
};
