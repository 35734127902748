import { Container, Flex, Spacer, Box, Text } from '@chakra-ui/react';
import { useTranslate } from '../languages';
import SocialMedialIcons from './SocialMediaIcons';
import LanguagesList from './LanguagesList';

interface HeaderProps {
  bg: string;
  color: string;
}

const Header: React.FC<HeaderProps> = ({ bg, color }) => {
  const t = useTranslate();
  return (
    <Box
      bg={bg}
      fontSize='md'
      display={{ base: 'none', lg: 'block' }}
      py={[null, null, null, '10px', null, '15px']}
      className='test-header'
    >
      <Container maxW='7xl'>
        <Flex flexDir={['column', 'row']}>
          <Box display='flex' columnGap='20px' color={color}>
            <Text>{t('languages')}</Text>
            <LanguagesList color={color} />
          </Box>
          <Spacer />

          <SocialMedialIcons isGray={bg === 'rgba(250, 250, 250, 1)'} />
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
