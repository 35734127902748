import { Box, Text, Image, Button, Flex } from '@chakra-ui/react';
import { ExamDetails } from '../../types';
import repeatIcon from '../../assests/icons/repeat.svg';
import langIcon from '../../assests/icons/lang.svg';
import { Link } from 'react-router-dom';
import { useTranslate } from '../../languages';

interface ExamCardProps extends ExamDetails {
  packageId: string;
}

const ExamCard: React.FC<ExamCardProps> = ({
  examId,
  examName,
  examRepeatCount,
  userRepeatCount,
  packageId,
}) => {
  const t = useTranslate();

  return (
    <Box
      fontFamily='Inter'
      fontWeight={600}
      bg='#fff'
      boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)'
      pb='10px'
    >
      <Box
        bg='#09003F'
        color='#fff'
        fontSize={['22px', null, null, null, null, '24px']}
        py='10px'
        px='20px'
      >
        <Text>{examName}</Text>
      </Box>
      <Box px='20px' py='10px'>
        {/* <Box display='flex' alignItems='center' columnGap='10px' mb='15px'>
          <Image boxSize={['15px', null, '20px']} src={langIcon} />
          <Text color='#09003F'>{t('language')}: </Text>
        </Box> */}
        <Box display='flex' alignItems='center' columnGap='10px' mb='15px'>
          <Image boxSize={['15px', null, '20px']} src={repeatIcon} />
          <Text color='#09003F'>
            {userRepeatCount - 1} / {examRepeatCount}
          </Text>
        </Box>
        <Flex justifyContent='flex-end' columnGap='10px'>
          {userRepeatCount > 1 && (
            <Link to={`/package/${packageId}/exam/${examId}/result`}>
              <Button
                colorScheme='darkBlue'
                size='sm'
                borderRadius={0}
                px='20px'
              >
                {t('last-result')}
              </Button>
            </Link>
          )}
          {/* {userRepeatCount <= examRepeatCount && ( */}
            <Button
              as={Link}
              to={`/package/${packageId}/exam/${examId}`}
              colorScheme='darkBlue'
              size='sm'
              borderRadius={0}
              px='20px'
            >
              {t('start-exam')}
            </Button>
          {/* )} */}
        </Flex>
      </Box>
    </Box>
  );
};

export default ExamCard;
