import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

import {
  SignupUserDetails,
  Package,
  User,
  ExamDetails,
  UserRecapData,
} from '../../../types/index';

export const signup = createAsyncThunk(
  'user/signup',
  async (userData: SignupUserDetails, { rejectWithValue }) => {
    try {
      const res = await api.post<User>('/User/Insert/User', userData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const login = createAsyncThunk(
  'user/login',
  async (
    user: { userEmail: string; userPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.post<User>('/User/User/login', user);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const loginByCode = createAsyncThunk(
  'loging/code',
  async (userCode: string, { rejectWithValue }) => {
    try {
      const res = await api.post<User>(`/User/User/login/By/Code`, { userCode });
      console.log(res);
      return res.data;
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(err.response?.data);
    }
  }
);

export const uploadImage = createAsyncThunk(
  'videos/upload',
  async (
    { data, userId }: { data: FormData; userId: number | string },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.post<string>(
        `/User/Insert/User/Image?userId=${userId}`,
        data,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserData = createAsyncThunk(
  'user/update',
  async (
    {
      userId,
      userName,
      userEmail,
      userPassword,
    }: {
      userId: number | string;
      userName: string;
      userEmail: string;
      userPassword: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const data = [
        {
          op: 'replace',
          path: '/userName',
          value: userName,
        },
        {
          op: 'replace',
          path: '/userEmail',
          value: userEmail,
        },
        {
          op: 'replace',
          path: '/userPassword',
          value: userPassword,
        },
      ];

      const res = await api.patch(`/User/Update/User/${userId}`, data);
      return {
        userName,
        userEmail,
        userPassword,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendEmail = createAsyncThunk(
  'user/send-email',
  async (email: string, { rejectWithValue }) => {
    try {
      const data = new FormData();
      data.append('ToEmail', email);
      const res = await api.post<string>('/User/Send/Email', data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      console.log(res.data);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getUserPackages = createAsyncThunk(
  'get/user/packages',
  async (
    { userId, langId }: { userId: number; langId: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<Package[]>(
        `/User/Select/Package/For/User/${langId}/${userId}`
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getPackageExams = createAsyncThunk(
  'get/packages/exams',
  async (
    { userId, packageId }: { userId: number; packageId: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<ExamDetails[]>(
        `/User/Select/Package/Exam/For/User/${userId}/${packageId}`
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getUserRecapData = createAsyncThunk(
  'user/recap',
  async ({ userId }: { userId: number }, { rejectWithValue }) => {
    try {
      const res = await api.post<UserRecapData>(
        `/User/Select/Package/Propertes/For/User/${userId}`
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendMessage = createAsyncThunk(
  'send/message',
  async (
    msg: { name: string; phone: string; email: string; subject: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post(`/User/Insert/Complaint`, msg);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

