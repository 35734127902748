import {
  Container,
  Box,
  Stack,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Avatar,
} from '@chakra-ui/react';
import './profile.scss';
import { NavLink, Outlet } from 'react-router-dom';
import settingsIcon from '../../assests/icons/settings.svg';
import logoutIcon from '../../assests/icons/logout.svg';
import subscribeIcon from '../../assests/icons/subscribe.svg';
import useLogout from '../../hooks/logout';
import { useTranslate } from '../../languages';
import { useAppSelector } from '../../app/hooks';
import { BASE_URL } from '../../util/constants';
import { useEffect } from 'react';

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = ({}) => {
  const handleLogout = useLogout();
  const t = useTranslate();
  const { userImage, email, username } = useAppSelector((state) => {
    return {
      userImage: state.user.data?.userImage,
      email: state.user.data?.userEmail,
      username: state.user.data?.userName,
    };
  });

  const imageUrl = BASE_URL + userImage;

  return (
    <Box className='profile'>
      <Container maxW='7xl' px='0'>
        <Stack direction={['column', null, 'row']} spacing='22px'>
          <Box bg='#fff' py='30px' w={['100%', null, '25%']} minH='100vh'>
            <Stack
              direction={['row', null, 'column']}
              alignItems='center'
              px='30px'
              gap='10px'
            >
              <Avatar src={imageUrl} size='lg' />
              <Box fontFamily='Inter' textAlign={[null, null, 'center']}>
                <Text
                  color='#000'
                  fontWeight={600}
                  fontSize={['17px', null, '19px', null, '20px', '24px']}
                >
                  {username}
                </Text>
                <Text
                  color='rgba(80, 79, 79, 0.8)'
                  fontWeight={400}
                  fontSize={['13px', null, '14px', '15px', '16px']}
                >
                  {email}
                </Text>
              </Box>
            </Stack>
            <Divider my='20px' borderBottomWidth='2px' w='90%' mx='auto' />
            <List
              fontFamily='Inter'
              fontWeight={500}
              // fontSize={['16px', '18px', '19px', '20px', '21px', '24px']}
              fontSize='1.1rem'
            >
              <NavLink to='/profile/suscribed-packs' className='navlink'>
                <ListItem display='flex' alignItems='center' gap='10px'>
                  <Image src={subscribeIcon} boxSize={['13px', null, '18px']} />
                  <Text>{t('subscribed-packs')}</Text>
                </ListItem>
              </NavLink>
              <NavLink to='/profile/edit' className='navlink'>
                <ListItem display='flex' alignItems='center' gap='10px'>
                  <Image src={settingsIcon} boxSize={['13px', null, '18px']} />
                  <Text>{t('edit-profile')}</Text>
                </ListItem>
              </NavLink>
              <Box className='navlink' cursor='pointer' onClick={handleLogout}>
                <ListItem display='flex' alignItems='center' gap='10px'>
                  <Image src={logoutIcon} boxSize={['13px', null, '18px']} />
                  <Text>{t('logout')}</Text>
                </ListItem>
              </Box>
            </List>
          </Box>
          <Outlet />
        </Stack>
      </Container>
    </Box>
  );
};

export default Profile;
