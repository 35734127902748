import { Box, Text, chakra, Flex, Image, Button } from '@chakra-ui/react';
import timeIcon from '../../assests/icons/time.svg';
import repeatIcon from '../../assests/icons/repeat.svg';
import noteIcon from '../../assests/icons/landing/note-2.svg';
import questionMarkIcon from '../../assests/icons/landing/question-mark.svg';
import { Package } from '../../types';
import { Link } from 'react-router-dom';
import { useTranslate } from '../../languages';

interface SubscriedPackageCardProps extends Package {}

const SubscriedPackageCard: React.FC<SubscriedPackageCardProps> = ({
  packageDuration,
  packageExamLanguage,
  packageOfferPrice,
  packagePrice,
  packageText,
  packageId,
}) => {
  const t = useTranslate();

  return (
    <Box
      boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)'
      pb='20px'
    >
      <Box bg='#09003F' p='20px 20px 10px'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text
            color='#fff'
            fontFamily='Gilroy-Bold'
            fontSize={['14px', '16px', '18px', null, '20px', '22px']}
          >
            Basic Pack With English
          </Text>
          <Text
            color='#F8AA05'
            fontFamily='Gilroy-Bold'
            fontSize={['15px', '17px', '19px', '21px', '23px', '25px']}
          >
            {packagePrice}€{' '}
            <chakra.sub
              color='#E6E6E6'
              fontFamily='Gilroy-SemiBold'
              fontSize={['7px', null, null, '8px', null, '9px', '10px']}
            >
              /30 {t('days')}
            </chakra.sub>
          </Text>
        </Box>
        <Flex
          fontFamily='Inter'
          fontWeight={600}
          fontSize={['7px', '8px', '9px', '10px', '11px', '12px']}
          color='#E6E6E6'
          columnGap='17px'
          mt='15px'
        >
          <Text>
            {t('language')}: {packageExamLanguage}
          </Text>
          {/* <Text>Pack renewal date: 25 Sep,2022</Text> */}
        </Flex>
      </Box>
      <Flex
        px='20px'
        py='15px'
        fontFamily='Inter'
        fontWeight={500}
        fontSize={['9px', '10px', '11px', '12px', '13px', '14px']}
        color='#09003F'
        flexWrap='wrap'
        columnGap='20px'
        rowGap='20px'
        mb='10px'
      >
        <Box display='flex' alignItems='center' columnGap='10px'>
          <Image boxSize={['15px', null, '20px']} src={timeIcon} />
          <Text>
            {packageDuration} {t('days')}
          </Text>
        </Box>
        <Box display='flex' alignItems='center' columnGap='10px'>
          <Image boxSize={['15px', null, '20px']} src={questionMarkIcon} />
          <Text>1500+ {t('questions')}</Text>
        </Box>
        <Box display='flex' alignItems='center' columnGap='10px'>
          <Image boxSize={['15px', null, '20px']} src={noteIcon} />
          <Text>20+ {t('exams')}</Text>
        </Box>
        <Box display='flex' alignItems='center' columnGap='10px'>
          <Image boxSize={['15px', null, '20px']} src={repeatIcon} />
          <Text>170+ {t('repetitions')}</Text>
        </Box>
      </Flex>
      <Link to={`/profile/suscribed-packs/${packageId}/exams-list`}>
        <Button
          colorScheme='darkBlue'
          borderRadius={0}
          px='30px'
          display='block'
          ms='auto'
          me='20px'
        >
          {t('exams-list')}
        </Button>
      </Link>
    </Box>
  );
};

export default SubscriedPackageCard;
