import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  // const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const isLoggedIn = sessionStorage.getItem('user');
  

  return isLoggedIn ? children : <Navigate to='/signin' />;
};

export default PrivateRoute;
