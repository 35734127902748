import {
  Accordion,
  AccordionItem,
  Box,
  Container,
  HStack,
  List,
  ListItem,
  Text,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import Logo from '../../../../components/Logo';
import DraggableQuestion from './DraggableQuestion';
import MultiChoiceQuestion from './MultiChoiceQuestion';
import QuestionHeader from './QuestionHeader';
import { useState, useEffect, useContext } from 'react';
import QuestionIndex from './QuestionIndex';
import { questionContext } from '../../../../contexts/QuestionContextProvider';
import { secondsToMinutes } from '../../../../util/timer';
import { useTranslate } from '../../../../languages';

interface QuestionProps {}

const Question: React.FC<QuestionProps> = ({}) => {
  const t = useTranslate();
  const [counter, setCounter] = useState(0);
  const [counterInterval, setCounterInterval] = useState<NodeJS.Timer | null>(
    null
  );
  const [questinInexOpen, setQuestinInexOpen] = useState(false);
  const { question, currentQuestionIndex, part, nextQuestion, examCounter } =
    useContext(questionContext);

  useEffect(() => {
    if (counterInterval) {
      clearInterval(counterInterval);
    }
    setCounter(0);
    const interval = setInterval(() => {
      setCounter((cnt) => cnt + 1);
    }, 1000);
    setCounterInterval(interval);
    return () => {
      clearInterval(interval);
    };
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (counter >= (part == 1 ? 8 : 45)) {
      nextQuestion();
    }
  }, [counter]);

  const toggleQuestionCounter = () => {
    if (counterInterval) {
      clearInterval(counterInterval);
      setCounterInterval(null);
    } else {
      const interval = setInterval(() => {
        setCounter((cnt) => (cnt == 8 ? cnt : cnt + 1));
      }, 1000);
      setCounterInterval(interval);
    }
  };

  const openQuestionInex = () => {
    setQuestinInexOpen(true);
  };

  const closeQuestionIndex = () => {
    setQuestinInexOpen(false);
  };

  const { minutes, seconds } = secondsToMinutes(examCounter);

  return (
    <Box bg='#E5E5E5' minH='100vh'>
      <QuestionIndex isOpen={questinInexOpen} closeModal={closeQuestionIndex} />
      <QuestionHeader
        counterWidthValue={Math.min(
          100,
          (counter / (part == 1 ? 8 : 45)) * 100
        )}
        toggleQuestionCounter={toggleQuestionCounter}
        isStopped={!counterInterval}
        openQuestionIndex={openQuestionInex}
      />

      <Container maxW='7xl'>
        <HStack
          spacing={[0, 0, '120px']}
          alignItems='flex-start'
          justifyContent={['center']}
        >
          <Box
            bg='#fff'
            w='30%'
            display={['none', null, 'block']}
            h='calc(100vh - 100px)'
          >
            <Box p='20px' pb='50px'>
              <Logo />
            </Box>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              bg='#09003F'
              color='#fff'
              fontFamily='Inter'
              p='20px'
            >
              <Text>{t('exam-timer')}</Text>
              <Text>
                {minutes}:{seconds}
              </Text>
            </Box>
            <List
              color='#09003F'
              fontFamily='Inter'
              fontSize={{ lg: '18px', xxl: '22px' }}
              my='20px'
            >
              {[1, 2, 3].map((item) => (
                <ListItem
                  key={item}
                  p='10px'
                  bg={part === item ? '#407BFF' : ''}
                  color={part === item ? '#fff' : ''}
                >
                  {t(`part-${item}`)}
                </ListItem>
              ))}
            </List>
            {!counterInterval && (
              <Accordion allowToggle fontFamily='Inter' defaultIndex={0}>
                <AccordionItem>
                  <AccordionButton
                    bg='#F2F2F2'
                    color='#09003F'
                    fontSize='20px'
                    fontWeight={700}
                  >
                    <Box as='span' flex='1' textAlign='left'>
                      {t('question-reason')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} color='#000'>
                    {question.questionReasone}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>
          {question.questionIsDraggable ? (
            <DraggableQuestion
              key={question.questionId}
              toggleQuestionCounter={toggleQuestionCounter}
            />
          ) : (
            <MultiChoiceQuestion />
          )}
        </HStack>
      </Container>
    </Box>
  );
};

export default Question;
