import AuthWraper, { Line } from './AuthWraper';
import { Alert, AlertIcon, Button, Input, Text } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useNotification from '../../hooks/notification';
import { login } from '../../app/features/user/actions';
import Loader from '../Loader';
import { useTranslate } from '../../languages';

interface LoginByEmailProps {}

const LoginByEmail: React.FC<LoginByEmailProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const navigate = useNavigate();
  const { loading, error } = useAppSelector((state) => {
    return {
      loading: state.user.loading,
      error: state.user.error,
    };
  });
  const [user, setUser] = useState({
    userEmail: '',
    userPassword: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(login(user))
      .then((res) => {
        if (res.type === login.fulfilled.type) {
          notify.success(t('welcome'));
          navigate('/home');
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <AuthWraper>
      {error && (
        <Alert status='error'>
          <AlertIcon />
          {error}
        </Alert>
      )}
      <Text
        fontFamily='Gilroy-Bold'
        fontSize={['16px', null, null, '20px', null, '32px']}
        mb='15px'
      >
        {t('signin')}
      </Text>
      <form onSubmit={handleLogin}>
        <Input
          name='userEmail'
          onChange={handleInputChange}
          type='email'
          variant='flushed'
          placeholder={t('email')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['13px', '16px', '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['13px', '16px', '20px'],
          }}
          mb='30px'
        />
        <Input
          name='userPassword'
          onChange={handleInputChange}
          type='password'
          variant='flushed'
          placeholder={t('pass')}
          fontFamily='Inter'
          fontWeight='500'
          fontSize={['13px', '16px', '20px']}
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: ['13px', '16px', '20px'],
          }}
          mb='10px'
        />
        <Text
          fontFamily='Inter'
          fontSize={['10px', '12px', '14px', '16px']}
          // textAlign='center'
          textDecoration='underline'
          mb='20px'
        >
          <Link to='/signin/code' style={{ color: '#F8AA05', fontWeight: 'bold' }}>
            {t('login-by-code')}
          </Link>
        </Text>
        {/* <Text
          fontFamily='Inter'
          fontSize={['10px', '12px', '14px', '16px']}
          textDecoration='underline'
          mb='20px'
        >
          <Link to='/forget-password' style={{ color: '#F8AA05', fontWeight: 'bold' }}>
            {t('forget-pass')}
          </Link>
        </Text> */}
        <Button
          type='submit'
          fontFamily='Inter'
          borderRadius='0'
          w='85%'
          display='block'
          mx='auto'
          colorScheme='darkBlue'
        >
          {loading ? <Loader size='md' /> : t('login')}
        </Button>
      </form>
      <Line />
      <Text
        fontFamily='Inter'
        fontSize={['10px', '12px', '14px', '16px', '18px', '20px']}
        textAlign='center'
      >
        {t('new-user')},{' '}
        <Link to='/signup' style={{ color: '#F8AA05', fontWeight: 'bold' }}>
          {t('signup')}
        </Link>
      </Text>
    </AuthWraper>
  );
};

export default LoginByEmail;
