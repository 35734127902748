import {
  Box,
  Text,
  chakra,
  UnorderedList,
  ListItem,
  Button,
} from '@chakra-ui/react';
import { useAppSelector } from '../app/hooks';
import { useTranslate } from '../languages';
import { Package } from '../types';
import { PAYMENT_URL } from '../util/constants';
import BuyPackage from './BuyPackage';
import Loader from './Loader';

interface PackageBestSellerCardProps extends Package {
  t: (text: string) => string;
  isRTL?: boolean;
}

const PackageBestSellerCard: React.FC<PackageBestSellerCardProps> = ({
  packageDuration,
  packageExamLanguage,
  packageId,
  packageOfferPrice,
  packageText,
  packagePrice,
  examCount,
  examReapetCount,
  t,
  isRTL,
}) => {
  return (
    <Box
      bg='rgba(16, 15, 15, 1)'
      color='rgba(255, 255, 255, 1)'
      px='20px'
      py='30px'
      boxShadow=' 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)'
      overflow='hidden'
    >
      <Text
        fontFamily='Gilroy-Bold'
        fontSize={['30px', null, null, null, '36px', '56px']}
        mb={['23px']}
        pos='relative'
        _after={{
          content: '"Best Selling"',
          pos: 'absolute',
          top: '-12px',
          [isRTL ? 'left' : 'right']: '-57px',
          backgroundColor: 'yellow.main',
          color: 'rgba(0, 0, 0, 1)',
          fontSize: '12px',
          fontFamily: 'Gilroy-Bold',
          transform: isRTL ? 'rotate(-45deg)' : 'rotate(45deg)',
          width: '140px',
          textAlign: 'center',
          py: '5px',
        }}
      >
        {packagePrice} €{' '}
        <chakra.sub
          fontFamily='Gilroy-SemiBold'
          fontSize={['12px', null, null, '15px', null, '24px']}
          color='rgba(255, 255, 255, 0.6)'
        >
          /{packageDuration} {t('days')}
        </chakra.sub>
      </Text>
      <Text
        fontFamily='Gilroy-Bold'
        fontSize={['26px', null, null, null, '22px', '32px']}
        mb={['36px']}
        bg='gray.main'
        color='#000'
        // h='60px'
        lineHeight='60px'
        mx='-20px'
        px='20px'
      >
        {packageText}
      </Text>
      <UnorderedList
        ps='10px'
        fontSize={['12px', null, '16px', null, '20px', '24px']}
        fontFamily='Inter'
      >
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          <chakra.span fontWeight='bold'>{t('language')}</chakra.span>
          {packageExamLanguage}
        </ListItem>
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          <chakra.span fontWeight='bold'>{examCount * 45} </chakra.span>
          {t('theory-questions')}
        </ListItem>
        <ListItem mb={['12px', null, '16px', null, '20px', '32px']}>
          <chakra.span fontWeight='bold'>{examReapetCount} </chakra.span>
          {t('repetition-for-exam')}
        </ListItem>
        <ListItem>
          <chakra.span fontWeight='bold'>{examCount} </chakra.span>
          {t('theory-exams')}
        </ListItem>
      </UnorderedList>
      <BuyPackage btnText={t('start-training')} packageId={packageId} />
    </Box>
  );
};

export default PackageBestSellerCard;
