import { Settings, User } from '../types';

export const saveUserInSessionStorage = (user: User) => {
  const userData = JSON.stringify(user);
  sessionStorage.setItem('user', userData);
};

export const getUserFromSessionStorage = (): User | null => {
  const user = sessionStorage.getItem('user');
  if (!user) {
    return null;
  }
  return JSON.parse(user);
};

export const saveSettingsInLocalStorage = (settings: Settings) => {
  const data = JSON.stringify(settings);
  sessionStorage.setItem('settings', data);
};

export const getSettingsFromLocalStorage = (): Settings | null => {
  const data = localStorage.getItem('settings');
  if (!data) {
    return null;
  }
  return JSON.parse(data);
};
