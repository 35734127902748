import {
  Box,
  Container,
  Image,
  Stack,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import image from '../../assests/images/landing/image-2.png';
import styled from 'styled-components';
import { useTranslate } from '../../languages';

interface LandingTraingJourneyProps {}

const LandingTraingJourney: React.FC<LandingTraingJourneyProps> = ({}) => {
  const t = useTranslate();
  return (
    <Container
      maxW='7xl'
      bgColor='rgba(250, 250, 250, 1)'
      display='flex'
      flexDirection={document.dir === 'rtl' ? 'row-reverse' : 'row'}
      mb={['45px', null, null, '100px', '120px', '200px']}
    >
      <Box w={['100%', null, null, '70%']} pt='25px'>
        <Text
          color='rgba(0, 0, 0, 1)'
          fontFamily='Gilroy-Bold'
          fontSize={['12px', '16px', '20px', '23px', '26px', '40px']}
          mb={['5px']}
        >
          {t('the-training-journey')}
        </Text>
        <Text
          color='rgba(80, 80, 80, 1)'
          fontWeight='500'
          fontSize={['7px', '12px', '15px', '18px', null, '27px']}
          mb={['15px']}
          fontFamily='Inter'
        >
          {t('training-journey-how-works')}{' '}
        </Text>
        <SimpleGrid
          columns={2}
          gap={['15px', '20px', '25px', '30px', '35px', '48px']}
          mb={['26px', null, null, '40px', '68px', '90px']}
          fontFamily='Inter'
        >
          <Card>
            <Text fontWeight='bold' fontSize='28px'>
              1
            </Text>
            <Text fontSize={['11px', '13px']}>{t('create-your-account')}</Text>
          </Card>
          <Card>
            <Text fontWeight='bold' fontSize='28px'>
              2
            </Text>
            <Text fontSize={['11px', '13px']}>{t('select-suitable-pack')}</Text>
          </Card>
          <Card>
            <Text fontWeight='bold' fontSize='28px'>
              3
            </Text>
            <Text fontSize={['11px', '13px']}>{t('start-training')}</Text>
          </Card>
        </SimpleGrid>
        <Bar />
      </Box>
      <Box display={['none', null, null, 'block']}>
        <Image src={image} w='100%' />
      </Box>
    </Container>
  );
};

export default LandingTraingJourney;

const Card = styled.div`
  font-family: inter;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 13px;
  color: rgba(250, 250, 250, 1);
  background-color: rgba(35, 70, 187, 1);
  height: 40px;
  clip-path: polygon(87% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
  @media (min-width: 767px) {
    height: 50px;
  }

  @media (min-width: 1200px) {
    height: 55px;
  }
  @media (min-width: 1500px) {
    height: 70px;
  }
`;

const Bar = styled.div`
  width: 80%;
  height: 15px;
  background-color: #f8aa05;
  clip-path: polygon(97% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: rgba(250, 250, 250, 1);
    transform: skewX(40deg);
  }

  &::before {
    right: 20px;
  }

  &::after {
    right: 40px;
  }
`;
