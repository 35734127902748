import { Spinner } from '@chakra-ui/react';

interface LoaderProps {
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xs';
}

const Loader: React.FC<LoaderProps> = ({ size }) => {
  return (
    <Spinner
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      {...(size === 'xxl' ? { boxSize: '100px' } : { size })}
      display='block'
      mx='auto'
    />
  );
};

export default Loader;
