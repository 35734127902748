import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_ERROR_MSG } from '../../../util/constants';
import {
  User,
  Package,
  ExamDetails,
  UserRecapData,
} from '../../../types/index';
import {
  signup,
  login,
  getUserPackages,
  getPackageExams,
  getUserRecapData,
  uploadImage,
  updateUserData,
  sendEmail,
  sendMessage,
  loginByCode,
} from './actions';
import { saveUserInSessionStorage } from '../../../util/local-session-storage';

interface InitialState {
  loading: boolean;
  error: string;
  isLoggedIn: boolean;
  data: User | null;
  packages: Package[];
  currentPackageExams: ExamDetails[];
  recapData: UserRecapData | null;
}

const initialState: InitialState = {
  loading: false,
  error: '',
  isLoggedIn: false,
  data: null,
  packages: [],
  currentPackageExams: [],
  recapData: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      sessionStorage.removeItem('user');
      return initialState;
    },
    loginUsingSession: (state, action: PayloadAction<User>) => {
      state.isLoggedIn = true;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // signup
    builder.addCase(signup.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload;
      state.isLoggedIn = true;
      saveUserInSessionStorage(state.data);
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // send email
    builder.addCase(sendEmail.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // login
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload;
      state.isLoggedIn = true;
      saveUserInSessionStorage(state.data);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // login by code
    builder.addCase(loginByCode.pending, (state, action) => {
      state.loading = true;
      state.error = ''
    });
    builder.addCase(loginByCode.fulfilled, (state, action) => {
      state.loading = false;
      state.error = ''
      state.data = action.payload;
      state.isLoggedIn = true;
      saveUserInSessionStorage(state.data);
    });
    builder.addCase(loginByCode.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // get user recap data
    builder.addCase(getUserRecapData.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getUserRecapData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.recapData = action.payload;
    });
    builder.addCase(getUserRecapData.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // packages
    builder.addCase(getUserPackages.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getUserPackages.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.packages = action.payload;
    });
    builder.addCase(getUserPackages.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // package exams
    builder.addCase(getPackageExams.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPackageExams.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.currentPackageExams = action.payload;
    });
    builder.addCase(getPackageExams.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // upload image
    builder.addCase(uploadImage.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      if (state.data) {
        state.data.userImage = action.payload;
        saveUserInSessionStorage(state.data);
      }
    });
    builder.addCase(uploadImage.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    // update user data
    builder.addCase(updateUserData.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      if (state.data) {
        state.data.userEmail = action.payload.userEmail;
        state.data.userName = action.payload.userName;
        state.data.userPassword = action.payload.userPassword;
        saveUserInSessionStorage(state.data);
      }
    });
    builder.addCase(updateUserData.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });

    builder.addCase(sendMessage.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });
  },
});

export default userSlice.reducer;
export const { logout, loginUsingSession } = userSlice.actions;
