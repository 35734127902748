import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { PAYMENT_URL } from '../util/constants';

interface BuyPackageProps {
  packageId: number;
  btnText: string;
}

const BuyPackage: React.FC<BuyPackageProps> = ({ packageId, btnText }) => {
  const navigate = useNavigate();
  const { userId } = useAppSelector((state) => {
    return {
      userId: state.user.data?.userId,
    };
  });
  const domain = `http://${window.location.host}`;

  if (!userId)
    return (
      <Button
        mt='76px'
        mx='auto'
        fontFamily='Inter'
        p='5px 28px'
        borderRadius='0'
        colorScheme='yellow'
        display='block'
        onClick={() => navigate('/signin')}
      >
        {btnText}
      </Button>
    );

  return (
    <form action={PAYMENT_URL} method='POST'>
      <input type='hidden' name='domain' value={domain} />
      <input type='hidden' name='packageId' value={packageId} />
      <input type='hidden' name='userId' value={userId} />
      <Button
        type='submit'
        mt='76px'
        mx='auto'
        fontFamily='Inter'
        p='5px 28px'
        borderRadius='0'
        colorScheme='yellow'
        display='block'
      >
        {btnText}
      </Button>
    </form>
  );
};

export default BuyPackage;
