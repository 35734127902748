import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useState, useEffect } from 'react';
import { UserAnswer } from '../../types/index';
import PartOne from './part/PartOne';
import PartTwo from './part/PartTwo';
import PartThree from './part/PartThree';
import { getExamPart, postUserAnswers } from '../../app/features/exam/actions';
import Question from './part/question/Question';
import QuestionContextProvider from '../../contexts/QuestionContextProvider';

interface ExamProps {
  isFree: boolean;
}

const Exam: React.FC<ExamProps> = ({ isFree }) => {
  const { examId: examIdInParam, packageId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { questions, userId, loading, langId, examId, examName } =
    useAppSelector((state) => {
      return {
        questions: state.exam.data?.Question,
        loading: state.exam.loading,
        userId: state.user.data?.userId,
        langId: state.settings.lang.id,
        examId: state.exam.data?.examId,
        examName: state.exam.data?.examName,
      };
    });

  const [part, setPart] = useState<number>(1);
  const [currentItem, setCurrentItem] = useState<'part' | 'question'>('part');
  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [examCounter, setExamCounter] = useState(2400);
  const [examCounterInterval, setExamCounterInterval] =
    useState<NodeJS.Timer | null>(null);

  useEffect(() => {
    console.log('Hi');
    if (userId && part <= 3) {
      dispatch(
        getExamPart({
          userId,
          examId: examIdInParam ? +examIdInParam : undefined,
          departmentId: part,
          langId,
          isFree,
        })
      ).then((res) => {
        if (res.type === getExamPart.fulfilled.type) {
          setUserAnswers((prev) => {
            return [
              ...prev,
              ...res.payload?.Question?.map((q: any) => {
                return {
                  userAnswers: [],
                  deptId: part,
                  examId: res.payload.examId,
                  userId,
                  questionId: q.questionId,
                };
              }),
            ];
          });
        }
      });
    }
  }, [part, userId, examIdInParam]);

  useEffect(() => {
    if (currentItem === 'part') {
      if (examCounterInterval) {
        clearInterval(examCounterInterval);
      }
    } else {
      const interval = setInterval(() => {
        setExamCounter((cnt) => cnt - 1);
      }, 1000);
      setExamCounterInterval(interval);
    }
  }, [currentItem]);

  const nextQuestion = () => {
    if (questions && currentQuestionIndex === questions.length - 1) {
      if (part + 1 >= 4) {
        dispatch(
          postUserAnswers({ packageId, answers: userAnswers, isFree })
        ).then((res) => {
          if (res.type === postUserAnswers.fulfilled.type) {
            navigate(`/package/${packageId}/exam/${examId}/result`);
          }
        });
        return;
      }
      setPart((p) => p + 1);
      setCurrentItem('part');
      setCurrentQuestionIndex(0);
      return;
    }
    setCurrentQuestionIndex((index) => index + 1);
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((index) => index - 1);
    }
  };

  const showPartQuestions = () => {
    setCurrentItem('question');
  };

  const addAnswer = (answer: UserAnswer) => {
    setUserAnswers((prev) => {
      return [...prev, answer];
    });
  };

  const editAnswer = (answer: UserAnswer) => {
    setUserAnswers((prev) => {
      return prev.map((a) => {
        if (a.deptId === answer.deptId && a.questionId === answer.questionId) {
          return answer;
        }
        return a;
      });
    });
  };

  const deleteAnswer = (answer: UserAnswer) => {
    return setUserAnswers((prev) => {
      return prev.filter((a) => {
        if (a.deptId === answer.deptId && a.questionId === answer.questionId) {
          return false;
        }
        return true;
      });
    });
  };

  const getUserQuestionAnswer = (questionId: number, partId: number) => {
    const answer = userAnswers.find(
      (a) => a.questionId === questionId && a.deptId === partId
    );
    if (!answer) {
      throw new Error('answer not found');
    }
    return answer;
  };

  const goToQuestion = (index: number) => {
    setCurrentQuestionIndex(index);
  };

  if (!userId) {
    return <h1>Error</h1>;
  }

  console.log('user answers', userAnswers);

  return (
    <>
      {currentItem === 'part' ? (
        part === 1 ? (
          <PartOne goToQuestions={showPartQuestions} />
        ) : part === 2 ? (
          <PartTwo goToQuestions={showPartQuestions} />
        ) : (
          <PartThree goToQuestions={showPartQuestions} />
        )
      ) : (
        examId && (
          <QuestionContextProvider
            value={{
              addAnswer,
              deleteAnswer,
              editAnswer,
              getUserQuestionAnswer,
              goToQuestion,
              nextQuestion,
              prevQuestion,
              part,
              question: questions![currentQuestionIndex],
              examId,
              userId,
              currentQuestionIndex,
              examCounter,
              examName
            }}
          >
            <Question />
          </QuestionContextProvider>
        )
      )}
    </>
  );
};

export default Exam;
