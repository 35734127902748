import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWebsiteContentData, Lang } from '../../../types';
import { DEFAULT_ERROR_MSG } from '../../../util/constants';
import { getWebsiteContent } from './actions';

interface InitialState {
  loading: boolean;
  error: '';
  lang: Lang;
  websiteContent: IWebsiteContentData | null;
}

const initialState: InitialState = {
  loading: false,
  error: '',
  lang: { id: 2, name: 'English' },
  websiteContent: null,
};


const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      if (action.payload.lang.id === 1) {
        document.dir = 'rtl';
      }
      return action.payload;
    },
    changeLanguage: (state, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
      if (state.lang.id === 1) {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }
      localStorage.setItem('settings', JSON.stringify(state));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebsiteContent.pending, (state, action) => {
      state.loading = true;
      state.error = ''
    });
    builder.addCase(getWebsiteContent.fulfilled, (state, action) => {
      state.loading = false;
      state.error = ''
      state.websiteContent = action.payload;
    });
    builder.addCase(getWebsiteContent.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR_MSG;
    });
  }
});

export default settingsSlice.reducer;
export const { setSettings, changeLanguage } = settingsSlice.actions;
