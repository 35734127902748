import { Box, Center, Container, Stack, Text } from '@chakra-ui/react';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import SocialMedialIcons from './SocialMediaIcons';
import { useTranslate } from '../languages';
import { useAppSelector } from '../app/hooks';

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const t = useTranslate();
  const footer = useAppSelector(
    (state) => state.settings.websiteContent?.footer
  );

  return (
    <Box bg='rgba(8, 17, 37, 1)' pt='40px' fontFamily='Inter'>
      <Container maxW='7xl'>
        <Center justifyContent={['start', 'start', 'center']}>
          <Logo
            imageStyle={{
              w: ['49px', null, '80px', null, null, '105px'],
            }}
            titleStyle={{
              fontSize: ['13px', '20px', null, null, '29px'],
            }}
            subTitleStyle={{
              fontSize: ['10px', '13px', '21px'],
              color: 'rgba(250, 250, 250, 1)',
            }}
          />
        </Center>
        <Text
          color='rgba(230, 230, 230, 1)'
          fontWeight='400'
          fontSize={['14px', '15px', '16px', null, '18px']}
          mt='24px'
          textAlign={[null, null, 'center']}
          lineHeight='200%'
        >
          {footer}
        </Text>
        <Stack
          direction={['column', 'column', 'row']}
          spacing='20px'
          pt='30px'
          pb={['20px', null, '100px']}
          justifyContent={[null, null, 'center']}
          fontSize={['14px', null, '16px', null, null, '20px']}
        >
          <Link to='/'>
            <Text fontWeight='600' color='rgba(114, 194, 252, 1)'>
              {t('training-journey')}
            </Text>
          </Link>
          <Link to='/packges'>
            <Text fontWeight='600' color='rgba(114, 194, 252, 1)'>
              {t('packages-pricing')}
            </Text>
          </Link>
          <Link to='/about'>
            <Text fontWeight='600' color='rgba(114, 194, 252, 1)'>
              {t('about-contact')}
            </Text>
          </Link>
        </Stack>
      </Container>
      <Stack direction={['column', 'column', 'row']}>
        <Box
          bg='rgba(248, 170, 5, 1)'
          color='rgba(20, 21, 23, 1)'
          h='69px'
          textAlign='center'
          fontSize='16px'
          fontWeight='500'
          {...(document.dir === 'rtl'
            ? { borderTopLeftRadius: '500px' }
            : { borderTopRightRadius: '500px' })}
          lineHeight='69px'
          order='1'
          flex='1'
          mt={['20px', null, 0]}
        >
          {t('footer.cpy-rights')}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          px={['15px', null, '25px']}
          order={[0, 0, 1]}
        >
          <SocialMedialIcons />
        </Box>
      </Stack>
    </Box>
  );
};

export default Footer;
