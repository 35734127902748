import api from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Package } from '../../../types';

export const getPackages = createAsyncThunk(
  'get/packages',
  async (languageId: number, { rejectWithValue }) => {
    try {
      const res = await api.get<Package[]>(
        `/User/Select/All/Package/For/User/${languageId}`
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);
