import {
  Box,
  Text,
  Image as ChakraImage,
  Button,
  chakra,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useRef, useState, useContext } from 'react';
import pauseIcon from '../../../../assests/icons/test.svg';
import styled from 'styled-components';
import QuestionControls from './QuestionControls';
import { questionContext } from '../../../../contexts/QuestionContextProvider';

import image from '../../../../assests/images/quesTest.png';
import { useTranslate } from '../../../../languages';

interface Point {
  x: number;
  y: number;
  id: number;
}

interface DraggableQuestionProps {
  toggleQuestionCounter: () => void;
}

const DraggableQuestion: React.FC<DraggableQuestionProps> = ({
  toggleQuestionCounter,
}) => {
  const t = useTranslate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [userAnswers, setUserAnswers] = useState<Point[]>([]);
  const [answers, setAnswers] = useState<Point[]>([]);
  const [pointsOnCanvas, setPointsOnCanvas] = useState<Point[]>([]);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null | undefined>(
    null
  );
  let snapshot: ImageData | null;

  const {
    question,
    part: deptId,
    examId,
    userId,
    getUserQuestionAnswer,
    addAnswer,
    editAnswer,
    nextQuestion,
  } = useContext(questionContext);

  const init = () => {
    const canvas = canvasRef.current;
    if (!canvas || !ctx) return;
    const img = new Image();
    // img.crossOrigin = 'anonymous';
    img.src = question.questionImage;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
    const coordinatesString = question.Answer[0].answerText;
    const coordinates = JSON.parse(
      // coordinatesString.slice(1, coordinatesString.length - 1)
      coordinatesString
    ) as Point[];

    setAnswers(coordinates);
    setPointsOnCanvas(coordinates);
    setUserAnswers([]);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    setCtx(canvas?.getContext('2d'));
    if (!canvas || !ctx) return;

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    init();
  }, [canvasRef.current]);

  useEffect(() => {
    putAnswerOnGlobalUserAnswers();
  }, [userAnswers]);

  const getSnapshot = () => {
    if (!canvasRef.current || !ctx) return snapshot;
    return null;
    // return ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const handleDropOnCanvas = (e: React.DragEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    if (!ctx || !canvasRef.current) return;
    if (!e.dataTransfer) return;
    const id = e.dataTransfer.getData('text');
    const { x, y, xPercent, yPercent } = getNearestPoint(
      { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY },
      pointsOnCanvas,
      canvasRef.current.width,
      canvasRef.current.height
    );

    setAnswers((answers) => {
      return answers.filter((a) => a.id != +id);
    });

    setPointsOnCanvas((points) => {
      return points.filter(
        (point) => point.x != xPercent || point.y != yPercent
      );
    });

    setUserAnswers((old) => [...old, { x: xPercent, y: yPercent, id: +id }]);

    if (snapshot) ctx.putImageData(snapshot, 0, 0);
    ctx.beginPath();
    ctx.fillStyle = '#fff';
    ctx.arc(x, y, 25, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();
    ctx.fillStyle = 'crimson';
    ctx.font = 'Inter';
    ctx.fillText(id, x - 5, y + 5);
  };

  const putAnswerOnGlobalUserAnswers = () => {
    const sortedUserAnswers = userAnswers.sort((a, b) => a.id - b.id);
    console.log({ sortedUserAnswers });
    const currentAnswer = {
      userAnswers: [JSON.stringify(sortedUserAnswers)],
      examId: examId!,
      deptId,
      userId,
      questionId: question.questionId,
    };
    const isAnswerExist = getUserQuestionAnswer(question.questionId, deptId);
    if (isAnswerExist) {
      editAnswer(currentAnswer);
    } else {
      addAnswer(currentAnswer);
    }
  };

  return (
    <Box w={['100%', null, '70%']}>
      <chakra.canvas
        w='100%'
        h={['190px', null, null, '320px', '350px', '400px']}
        // border='1px solid crimson'
        ref={canvasRef}
        onDrop={handleDropOnCanvas}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      ></chakra.canvas>
      <Text
        fontFamily='Gilroy-Bold'
        color='#09003F'
        fontSize={['24px', null, null, null, '18px', '30px']}
        my='20px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        {question.questiontext}
        <Button
          bg='#D9D9D9'
          display={['none', null, 'flex']}
          justifyContent='space-between'
          alignItems='center'
          w='105px'
          isActive
          borderRadius={0}
          _active={{ bg: '#407BFF', color: '#fff' }}
          onClick={toggleQuestionCounter}
        >
          {t('pause')}
          <ChakraImage src={pauseIcon} boxSize='20px' />
        </Button>
      </Text>
      <Flex flexWrap='wrap' gap='20px' mb='60px'>
        {answers.length ? (
          answers.map((answer) => (
            <DroggableCard
              key={Math.random() + Date.now()}
              boxSize={['40px', null, null, '50px', '55px', '80px']}
              draggable={true}
              onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
                const canvas = canvasRef.current;
                if (!canvas || !ctx) return;
                snapshot = getSnapshot();
                e.dataTransfer.setData('text', answer.id.toString());
                showCircles(pointsOnCanvas, ctx!, canvas.width, canvas.height);
              }}
              onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
                if (!ctx || !snapshot) return;
                ctx.putImageData(snapshot, 0, 0);
              }}
            >
              {answer.id}
            </DroggableCard>
          ))
        ) : (
          <Button colorScheme='blue' onClick={init}>
            {t('reanswer')}
          </Button>
        )}
      </Flex>
      <QuestionControls next={nextQuestion} />
    </Box>
  );
};

export default DraggableQuestion;

const DroggableCard = chakra(styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a3a2a2;
  font-family: 'Gilroy-Bold';
  cursor: pointer;

  &:hover {
    color: #09003f;
  }
`);

// helper functions
const showCircles = (
  coordinates: { x: number; y: number }[],
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
) => {
  for (let item of coordinates) {
    ctx.beginPath();
    ctx.fillStyle = '#407BFF';
    const x = Math.ceil((item.x / 100) * width),
      y = Math.ceil((item.y / 100) * height);
    ctx.arc(x, y, 20, 0, 2 * Math.PI);
    ctx.fill();
    // ctx.stroke();
    ctx.fillStyle = '#fff';
    ctx.font = '26px Inter';
    ctx.fillText('?', x - 5, y + 5);
  }
};
const getNearestPoint = (
  { x, y }: { x: number; y: number },
  coordinates: { x: number; y: number }[],
  width: number,
  height: number
) => {
  const diff = [];
  let minDiff = 10000;
  // convert x and y cooridante to percentage
  x = (x / width) * 100;
  y = (y / height) * 100;
  for (let item of coordinates) {
    const d = Math.sqrt(Math.pow(x - item.x, 2) + Math.pow(y - item.y, 2));
    diff.push(d);
    minDiff = Math.min(minDiff, d);
  }
  for (let i = 0; i < coordinates.length; i++) {
    if (minDiff === diff[i]) {
      return {
        x: (coordinates[i].x / 100) * width,
        y: (coordinates[i].y / 100) * height,
        xPercent: coordinates[i].x,
        yPercent: coordinates[i].y,
      };
    }
  }
  return { x: 0, y: 0, xPercent: 0, yPercent: 0 };
};

// "'[{"x":10,"y":18,"id":1},{"x":39,"y":45,"id":2},{"x":93,"y":90,"id":3},{"x":50,"y":90,"id":4}]'"
