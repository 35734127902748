import { useAppSelector } from '../app/hooks';
import facebookIcon from '../assests/icons/header/facebook.svg';
import mailIcon from '../assests/icons/header/mail.svg';
import phoneIcon from '../assests/icons/header/phone.svg';
import youtubeIcon from '../assests/icons/header/youtube.svg';

import { HStack, Image } from '@chakra-ui/react';

interface SocialMedialIconsProps {
  [key: string]: any;
}

const SocialMedialIcons: React.FC<SocialMedialIconsProps> = ({
  isGray,
  ...props
}) => {
  const { phone, email } = useAppSelector((state) => {
    return {
      phone: state.settings.websiteContent?.pohone,
      email: state.settings.websiteContent?.email,
    };
  });

  if (isGray) {
    props.filter =
      'brightness(0) saturate(100%) invert(23%) sepia(23%) saturate(7311%) hue-rotate(221deg) brightness(88%) contrast(99%)';
  }

  return (
    <HStack spacing='14px'>
      <a target='_blank' href={`mailto:${email}`}>
        <Image boxSize='28px' src={mailIcon} {...props} />
      </a>
      <a target='_blank' href={`https://wa.me/${phone}`}>
        <Image boxSize='28px' fill='blue.main' src={phoneIcon} {...props} />
      </a>
      <a
        target='_blank'
        href='https://www.facebook.com/profile.php?id=100063469545128'
      >
        <Image boxSize='28px' src={facebookIcon} {...props} />
      </a>
      {/* <a target='_blank' href='https://youtube.com'>
        <Image boxSize='28px' src={youtubeIcon} {...props} />
      </a> */}
    </HStack>
  );
};

export default SocialMedialIcons;
