import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Navbar from '../components/navbar/Navbar';

interface NavbarWrapperProps {}

const NavbarWrapper: React.FC<NavbarWrapperProps> = ({}) => {
  return (
    <>
      <Header bg='blue.main' color='white' />
      <Navbar bg='rgba(250, 250, 250, 1)' color='#000' />
      <Outlet />
    </>
  );
};

export default NavbarWrapper;
