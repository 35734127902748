import { extendTheme } from '@chakra-ui/react';

const colors = {
  blue: {
    main: '#2346BB',
    '500': '#2346BB',
    '600': '#09003F',
  },
  darkBlue: {
    '500': 'rgba(9, 0, 63, 1)',
    '600': 'rgba(9, 0, 63, .8)',
  },
  yellow: {
    main: 'rgba(248, 170, 5, 1)',
    '500': 'rgba(248, 170, 5, 1)',
    '600': 'rgb(189 137 28)',
  },
  gray: {
    main: 'rgba(255, 255, 255, 1)',
  },
};

const theme = extendTheme({ colors });

export default theme;
