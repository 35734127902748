import { Box, Divider, HStack, Image, Text } from '@chakra-ui/react';
import repeatIcon from '../../assests/icons/repeat.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CardWrapper from './CardWrapper';

interface RepetitionChartProps {
  title: string;
  text: string;
  totalNumber: number;
  subNumber: number;
}

const RepetitionChart: React.FC<RepetitionChartProps> = ({
  title,
  text,
  totalNumber,
  subNumber,
}) => {
  const subNumberPercent = Math.round((subNumber / totalNumber) * 100);
  // console.log({subNumber, totalNumber, subNumberPercent})
  return (
    <CardWrapper title={title} icon={repeatIcon}>
      <HStack spacing='20px'>
        <Box w='100px'>
          <CircularProgressbar
            value={subNumberPercent}
            maxValue={100}
            background
            strokeWidth={12}
            styles={buildStyles({
              backgroundColor: '#fff',
              trailColor: '#EFEFEF',
              pathColor: '#4D84FF',
              rotation: -0.1,
            })}
          />
        </Box>
        <Box>
          <Text color='#4B82FF' fontFamily='Gilroy-Bold' fontSize='24px'>
            {subNumber}/{totalNumber}
          </Text>
          <Text
            fontSize='20px'
            fontFamily='Inter'
            fontWeight={500}
            color='#rgba(0, 0, 0, 0.8)'
          >
            {text}
          </Text>
        </Box>
      </HStack>
    </CardWrapper>
  );
};

export default RepetitionChart;
