import { Box, Image, chakra } from '@chakra-ui/react';

import imageLg from '../../../assests/images/exam/part2-lg.png';
import imageSm from '../../../assests/images/exam/part2-sm.png';
import { useTranslate } from '../../../languages';
import PartWraper from './PartWraper';

interface PartTwoProps {
  goToQuestions: () => void;
}

const PartTwo: React.FC<PartTwoProps> = ({ goToQuestions }) => {
  const t = useTranslate();
  return (
    <Box>
      <PartWraper
        title={t('part2.title')}
        button={{ text: t('start-exam'), onClick: goToQuestions }}
        part={t('part-2')}
        cards={[
          { text: t('questions'), number: 12 },
          { text: t('each-one'), number: 40 },
          { text: t('to-pass'), number: 10 },
        ]}
        text={t('part2.description')}
      >
        <chakra.picture
          order={[0, 0, 1]}
          w={['100%', null, '40%']}
          maxH={['180px', null, '100vh']}
        >
          <source media='(min-width: 768px)' srcSet={imageLg} />
          <source media='(max-width: 768px)' srcSet={imageSm} />
          <Image src={imageLg} h={{ base: '200px', md: '100%' }} w='100%' />
        </chakra.picture>
      </PartWraper>
    </Box>
  );
};

export default PartTwo;
