import {
  Box,
  Container,
  List,
  ListItem,
  Stack,
  Text,
  Image,
  AspectRatio,
  Input,
  Textarea,
  Button,
  HStack,
} from '@chakra-ui/react';
import smsIcon from '../assests/icons/contact/sms.svg';
import callIcon from '../assests/icons/contact/call.svg';
import locationIcon from '../assests/icons/contact/location.svg';
import { useTranslate } from '../languages';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { sendMessage } from '../app/features/user/actions';
import useNotification from '../hooks/notification';
import Loader from './Loader';

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const [message, setMessage] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
  });

  const { loading, websiteContent } = useAppSelector((state) => {
    return {
      loading: state.user.loading,
      websiteContent: state.settings.websiteContent,
    };
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSendMessage = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(sendMessage(message)).then((res) => {
      if (res.type === sendMessage.fulfilled.type) {
        notify.success(t('msg-success'));
        setMessage({
          name: '',
          phone: '',
          email: '',
          subject: '',
        });
      } else {
        notify.error(t(res.payload as string));
      }
    });
  };

  return (
    <Container
      maxW='6xl'
      bg='rgba(250, 250, 250, 1)'
      boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08)'
      py='30px'
    >
      <Stack direction={['column', null, 'row']} spacing='20px'>
        <Box w={['100%', null, '50%']}>
          <Text
            fontFamily='Gilroy-Bold'
            fontSize={['13px', '16px', null, '20px', null, '32px']}
            mb='22px'
          >
            {t('do-you-have-qs')} ?<br />
            {t('contact-us')}
          </Text>
          <Text
            color='rgba(51, 51, 51, 1)'
            fontSize={['13px', null, '16px', null, null, '24px']}
          >
            {t('contact-info')}
          </Text>
          <List
            mt='30px'
            fontFamily='Inter'
            fontSize={['10px', '14px', '16px', '20px', null, '24px']}
          >
            <ListItem display='flex' columnGap='26px' mb='20px'>
              <Image boxSize='20px' src={callIcon} />
              <Text>{websiteContent?.pohone}</Text>
            </ListItem>
            <ListItem display='flex' columnGap='26px' mb='20px'>
              <Image boxSize='20px' src={locationIcon} />
              <Text>{websiteContent?.address}</Text>
            </ListItem>
            <ListItem display='flex' columnGap='26px' mb='20px'>
              <Image boxSize='20px' src={smsIcon} />
              <Text>{websiteContent?.email}</Text>
            </ListItem>
          </List>
          <AspectRatio
            h={['100px', null, null, '120px', '160px', '230px']}
            ratio={16 / 9}
          >
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.2738153171945!2d4.615780615470961!3d52.45606834871806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5f1ccccd65d59%3A0x40fb9740cb9c822f!2sVan%20Ostadestraat%205%2C%201971%20XG%20IJmuiden%2C%20Netherlands!5e0!3m2!1sen!2seg!4v1679256745489!5m2!1sen!2seg'
              width='600'
              height='450'
              style={{ border: 0 }}
              allowFullScreen={false}
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            />
          </AspectRatio>
        </Box>
        <Box w={['100%', null, '50%']} bg='rgba(255, 255, 255, 1)' p='20px'>
          <Text
            color='rgba(51, 51, 51, 0.68)'
            fontFamily='Inter'
            fontSize={['8px', '12px', null, '16px', null, '20px']}
            mb='25px'
          >
            {t('contactform.title')}
          </Text>
          <form onSubmit={handleSendMessage}>
            <Input
              value={message.name}
              onChange={handleInputChange}
              name='name'
              variant='flushed'
              placeholder={t('full-name')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize={['10px', null, null, '20px']}
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: ['10px', null, null, '20px'],
              }}
              mb='30px'
            />
            <Input
              value={message.phone}
              onChange={handleInputChange}
              name='phone'
              variant='flushed'
              placeholder={t('phone-number')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize={['10px', null, null, '20px']}
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: ['10px', null, null, '20px'],
              }}
              mb='30px'
            />
            <Input
              value={message.email}
              onChange={handleInputChange}
              name='email'
              type='email'
              variant='flushed'
              placeholder={t('email')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize={['10px', null, null, '20px']}
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: ['10px', null, null, '20px'],
              }}
              mb='30px'
            />
            <Textarea
              value={message.subject}
              onChange={handleInputChange}
              name='subject'
              placeholder={t('subject')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize={['10px', null, null, '20px']}
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: ['10px', null, null, '20px'],
              }}
              mb='60px'
            />
            <Button
              type='submit'
              colorScheme='darkBlue'
              display='block'
              mx='auto'
              px='70px'
            >
              {loading ? <Loader size='md' /> : t('send-msg')}
            </Button>
          </form>
        </Box>
      </Stack>
    </Container>
  );
};

export default ContactForm;
